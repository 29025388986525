import People from '../assets/people.png';
import darkPeopleAdd from '../assets/peopleAdd.png';
import darkEgg from '../assets/egg.png';
import darkEggAdd from '../assets/eggAdd.png';
import darkJournal from '../assets/journal.png';
import darkJournalAdd from '../assets/journalAdd.png';
import darkLogout from '../assets/logout.png';
import darkPeople from '../assets/darkPeople.png';
import lightPeopleAdd from '../assets/lightPeopleAdd.png';
import lightEgg from '../assets/lightEgg.png';
import lightEggAdd from '../assets/lightEggAdd.png';
import lightJournal from '../assets/lightJournal.png';
import lightJournalAdd from '../assets/lightJournalAdd.png';
import lightLogout from '../assets/lightLogout.png';
import DashboardIcon from '../assets/dashboardIcon.png';
import DashboardIconLight from '../assets/dashboardIconLight.png';
export const column1 = [
  {
    id: 1,
    key: 'status',
    label: 'Status',
    type: 'switch',
  },
  {
    id: 2,
    key: 'firstName',
    label: 'First Name',
    type: 'text',
  },
  {
    id: 3,
    key: 'lastName',
    label: 'Last Name',
    type: 'text',
  },
  {
    id: 4,
    key: 'phoneNumber',
    label: 'Phone Name',
    type: 'number',
  },
  {
    id: 5,
    key: 'gender',
    label: 'Gender',
    type: 'select',
    options: [
      {label: 'Male', value: 'male'},
      {label: 'Female', value: 'female'},
    ],
  },
  {
    id: 6,
    key: 'birthdate',
    label: 'Birthdate',
    type: 'date',
  },
  {
    id: 7,
    key: 'currentWeight',
    label: 'Current Weight',
    type: 'text',
  },
  {
    id: 8,
    key: 'height',
    label: 'Height',
    type: 'text',
  },
];
export const column2 = [
  {
    id: 1,
    key: 'dailyGoalNutrition',
    label: 'User daily goal nutrition',
    type: 'text',
  },
  {
    id: 2,
    key: 'dailyGoalWater',
    label: 'User daily goal water',
    type: 'text',
  },
  {
    id: 3,
    key: 'dailyGoalActivity',
    label: 'User daily goal activity',
    type: 'text',
  },
  {
    id: 4,
    key: 'dailyGoalWeight',
    label: 'User daily goal weight',
    type: 'text',
  },
  {
    id: 5,
    key: 'dailyGoalSteps',
    label: 'User daily goal steps',
    type: 'text',
  },
  {
    id: 6,
    key: 'fitnessLevel',
    label: 'Fitness level',
    type: 'select',
    options: [
      {label: 'Good', value: 'good'},
      {label: 'Average', value: 'average'},
      {label: 'Bad', value: 'bad'},
    ],
  },
  {
    id: 7,
    key: 'targetWeight',
    label: 'Target weight',
    type: 'text',
  },
  {
    id: 8,
    key: 'timeToSleep',
    label: 'Time to sleep',
    type: 'time',
  },
];
export const column3 = [
  {
    id: 1,
    key: 'healthNeed',
    label: 'Health need',
    type: 'text',
  },
  {
    id: 2,
    key: 'healthIssues',
    label: 'Health issues',
    type: 'text',
  },
  {
    id: 3,
    key: 'healthStatus',
    label: 'Health status',
    type: 'text',
  },
  {
    id: 4,
    key: 'bloodPressure',
    label: 'Blood pressure',
    type: 'text',
  },
  {
    id: 5,
    key: 'bloodSugar',
    label: 'Blood sugar',
    type: 'text',
  },
  {
    id: 6,
    key: 'medicalRecommandations',
    label: 'Medical recommandations',
    type: 'text',
  },
  {
    id: 7,
    key: 'pregnant',
    label: 'Pregnant',
    type: 'text',
  },
  {
    id: 8,
    key: 'breastFeeding',
    label: 'Breast feeding',
    type: 'text',
  },
  {
    id: 9,
    key: 'exerciseLimitation',
    label: 'Exercise limitation',
    type: 'select',
    options: [
      {label: 'Good', value: 'good'},
      {label: 'Average', value: 'average'},
      {label: 'Bad', value: 'bad'},
    ],
  },
  {
    id: 10,
    key: 'foodPreferances',
    label: 'Food preferances',
    type: 'text',
  },
  {
    id: 11,
    key: 'foodSensitivity',
    label: 'Food Sensetivity',
    type: 'text',
  },
  {
    id: 12,
    key: 'foodMethod',
    label: 'Food Method',
    type: 'text',
  },
];
export const reminders = [
  {
    id: 1,
    label: 'Water',
    key: 'water',
  },
  {
    id: 2,
    label: 'Food',
    key: 'food',
  },
  {
    id: 3,
    label: 'Steps',
    key: 'steps',
  },
  {
    id: 4,
    label: 'Activity',
    key: 'activity',
  },
  {
    id: 5,
    label: 'Weight',
    key: 'weight',
  },
];
export const specialPreferences = [
  {
    id: 1,
    label: 'Vegetarian',
    key: 'vegetarian',
  },
  {
    id: 2,
    label: 'Vegan',
    key: 'vegan',
  },
  {
    id: 3,
    label: 'Fruity',
    key: 'fruity',
  },
];
export const articles = [
  {
    id: 1,
    imgUrl:
      'https://s3-alpha-sig.figma.com/img/43ba/61f0/b398485fb7e3197c688396042d8303f1?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=A5Tz94PPk89RwQibYqyIYTRGUSFKVOHteyH2dd61riU0pp58IuhLeoYyj3gVg7cG8LbzHbSN~83uzZhzsoL9-BWiTNtTe5g7od~XBT~aKSusVmtUb4OADkPf3NmXRIkGMn8~Cr5Rf53ey4URFvKgU9lzuqdO26HQ77DUphBZuiz44RCmXvRaNnv9WQfTARjmkoNxvk1DdlRrbipGfsDDlzgOyBEY~RtDmc-oKNOWdN6bmCWDNQhkzwy~c1elPA3XZDwWFTT4U0KNEl7GM7RFRVnSlCvpWqYCDi6VkgiNdNVH6eQ7yaSifO-HTDLxYyJswSGcb2jGZNwZwxHM~xR4MA__',
    text: 'Understanding Cholesterol: The Good, The Bad, and The Ugly',
  },
  {
    id: 2,
    imgUrl:
      'https://s3-alpha-sig.figma.com/img/69e4/1ffb/52141319666ae57e3399dce35d9bd2ad?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=frmUgTt~4BFeFSZarRnRExcp-x7Gw2SYyueR-DP1LgXVBAFNQQtrsqE~YnSuXjzpVkdTZHgP4YLZOQcmyXDQkV1jWTYB2u1RLPxaFhMkxH066XbshEPdo8lyrm-dO8xgNUVMednenzCY31wD2aekm2a~mfFVDvzpB-La1-ShDKbSV9iNxSO5tCE8oCbN8FoNkQ0lYcahDtqxHojtEQEeCNKfUBwGPUwxMvBHZmwMDeebWNpGxsrINJq9kExLZS3BgbkmnuXOLzASN5PbzUD3pznffoQH2SkUYTTqcD-fOnG3a~7SDE-e1c4vraKef9j96uZPExyTgTbWujesxo-eHA__',
    text: 'What If My Nutrition Program is Not Covered By Insurance?',
  },
  {
    id: 3,
    imgUrl:
      'https://s3-alpha-sig.figma.com/img/0bce/63ae/7ffe549982cb44a347bca64a3bf6cb65?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=klwO9YlxGVf~DYYWUg3WP2RevHMZeQP78Vg0Ndgp8IvIrIhkbzhZPJsfXDEwWoz3vbTu8fVYLDFQv2Kdtq3RKRHmQyaPQAA6Wwd2E6-666YF2MaFJvDwYMAWIpFCHRQyNh-qOyQc~2Qao6fawehWOvPta6QPxWAet~Jk7HlSfMv8t99Qmql7GMWl3pz-~sV6jJwSPr2NVby46uCPoj5n2HZl058JRXn4CyZBZUjIXep9OmBDPP9se-XdpZuVQLWfDuaiExLxgul0fKJ~IFtsvv0Zbpi7eCvic06nN13VWx~pwTLTs9ksmEhasZCUeLCj8HT1daF3OZtOo~~xT8Wwdg__',
    text: 'Understanding Cholesterol: The Good, The Bad, and The Ugly',
  },
  {
    id: 4,
    imgUrl:
      'https://png.pngtree.com/thumb_back/fh260/background/20230610/pngtree-many-different-kinds-of-dishes-and-foods-image_2951715.jpg',
    text: 'What If My Nutrition Program is Not Covered By Insurance?',
  },
];
export const genders = [
  {
    id: 1,
    label: 'Male',
    key: 'male',
    type: 'gender',
  },
  {
    id: 2,
    label: 'Female',
    key: 'female',
    type: 'gender',
  },
  {
    id: 3,
    label: 'Other',
    key: 'otherGender',
    type: 'gender',
  },
];
export const planTypes = [
  {
    id: 1,
    label: 'Weight loss',
    key: 'weightLoss',
    type: 'planTypes',
  },
  {
    id: 2,
    label: 'Muscle gain',
    key: 'muscleGain',
    type: 'planTypes',
  },
  {
    id: 3,
    label: 'Other',
    key: 'otherPlanTypes',
    type: 'planTypes',
  },
];
export const ages = [
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
];

export const planTags = ['healthy', 'balanced'];
export const measurements = ['gram', 'spoon', 'glass'];
export const eatingTimes = [
  {
    id: 1,
    label: 'Breakfast',
    key: 'breakfast',
  },
  {
    id: 2,
    label: 'After Breakfast',
    key: 'afterBreakfast',
  },
  {
    id: 3,
    label: 'Lunch',
    key: 'lunch',
  },
  {
    id: 4,
    label: 'After Lunch',
    key: 'afterLunch',
  },
];

export const navbarOptions = [
  {
    id: 8,
    label: 'Dashboard',
    path: '/dashboard',
    darkIcon: DashboardIcon,
    lightIcon: DashboardIconLight,
    key: '8',
  },
  {
    id: 1,
    label: 'Clients',
    path: '/',
    darkIcon: darkPeople,
    lightIcon: People,
    key: '1',
  },

  {
    id: 2,
    label: 'Add New Client',
    path: '/addNewClient',
    darkIcon: darkPeopleAdd,
    lightIcon: lightPeopleAdd,
    key: '2',
  },
  {
    id: 3,
    label: 'Nutritionist Plans',
    path: '/nutritionistPlans',
    darkIcon: darkEgg,
    lightIcon: lightEgg,
    key: '3',
  },
  {
    id: 4,
    label: 'Add New Plan',
    path: '/nutrition-plans',
    darkIcon: darkEggAdd,
    lightIcon: lightEggAdd,
    key: '4',
  },
  {
    id: 5,
    label: 'Articles',
    path: '/articles',
    darkIcon: darkJournal,
    lightIcon: lightJournal,
    key: '5',
  },
  {
    id: 6,
    label: 'Add New Article',
    path: '/addNewArticle',
    darkIcon: darkJournalAdd,
    lightIcon: lightJournalAdd,
    key: '6',
  },
  {
    id: 7,
    label: 'Logout',
    path: '/logout',
    darkIcon: darkLogout,
    lightIcon: lightLogout,
    key: '7',
  },
];

export const UNIT_CONVERSIONS = {
  gram: 1,
  spoon: 5,
  glass: 200,
  unit: 50,
  milliliter: 1,
};

// Default density values (g/ml) for common ingredients
export const DEFAULT_DENSITIES = {
  water: 1,
  milk: 1.03,
  oil: 0.92,
  honey: 1.42,
  flour: 0.53,
  sugar: 0.8,
  default: 1, // Fallback if the ingredient type is unknown
};
