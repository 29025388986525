import React, {ReactNode, createContext, useContext, useState} from 'react';

// Define context type
interface TrainerContextType {
  trainerId: number | null;
  setTrainerId: React.Dispatch<React.SetStateAction<number | null>>;
}

// Create context with default value
export const TrainerContext = createContext<TrainerContextType | null>(null);

// Provider component
interface TrainerContextProviderProps {
  children: ReactNode;
}

function TrainerContextProvider({children}: TrainerContextProviderProps) {
  const [trainerId, setTrainerId] = useState<number | null>(null);

  return (
    <TrainerContext.Provider value={{trainerId, setTrainerId}}>
      {children}
    </TrainerContext.Provider>
  );
}

// Custom hook
export const useTrainer = (): TrainerContextType => {
  const context = useContext(TrainerContext);
  if (!context) {
    throw new Error('useTrainer must be used within a TrainerContextProvider');
  }
  return context;
};

export default TrainerContextProvider;
