import React, {useEffect, useState} from 'react';
import Header from '../../components/Header';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {Checkbox, ListItemText, Menu, MenuItem} from '@mui/material';
import ClientsTable from './trainersTable';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import Loader from '../../components/Loader';
import {SearchSvg, ColSelectSvg, EditSvg, BasketSvg} from '../../assets';
import {useDebounce} from '../../services/debounce';
import {fetchTrainers} from '../../services/trainers';
import {AxiosError} from 'axios';
import {deleteUser} from '../../services/clients';
import CustomModal from '../../components/Modal';
type Props = {};

function Index({}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: 'profile_photo',
      headerName: t('image'),
      sortable: false,
      // disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      width: 70,
      minWidth: 70,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams<any>) => {
        const userImg = () => {
          const defaultImageUrl =
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdpRvftRBgfCbvzOHB0bANVih3QvZD-xZ4flbABUFGDctmaY87ajkJD5RhdvVcyZvkS7U&usqp=CAU';

          if (!params.value) {
            return defaultImageUrl;
          }

          if (params.value.startsWith('https:')) {
            const imageBaseUrl = 'https://scaleapp-images.s3.amazonaws.com';
            const imageUrl = `${imageBaseUrl}/${params.value.split('/').pop()}`;
            return imageUrl;
          }

          return params.value;
        };

        return (
          <div className="flex items-center justify-center">
            <div className="rounded-full overflow-hidden w-10 h-10">
              <img
                src={userImg()}
                alt="img"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        );
      },
    },
    {
      field: 'fname',
      headerName: t('firstname'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'lname',
      headerName: t('lastname'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'phone_number',
      headerName: t('phone'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'age',
      headerName: t('age'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'current_weight',
      headerName: t('currentweight'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'nutrition_plan',
      headerName: t('planAssigned'),
      width: 200,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams<any>) =>
        params.value ? (
          <div className="text-center border border-border-color rounded-full">
            <p className="text-xs font-[400] py-1 px-8">
              {params.value.plan_name ?? ''}
            </p>
          </div>
        ) : (
          ''
        ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 200,
      minWidth: 150,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="flex justify-center items-center gap-4">
          <img
            src={EditSvg}
            alt="Edit"
            onClick={() => handleEdit(params.row)}
            className="cursor-pointer"
          />
          <img
            src={BasketSvg}
            alt="Delete"
            onClick={() => handleDelete(params.row.id)}
            className="cursor-pointer"
          />
        </div>
      ),
    },
  ];
  const [tableColumns, setTableColumns] = useState(
    columns.map(col => col.field),
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const debouncedSearch = useDebounce(searchQuery, 300);
  const [delUserId, setDelUserId] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useQuery(
    ['trainers', page, pageSize, debouncedSearch],
    () => fetchTrainers(page, pageSize, debouncedSearch),
    {
      keepPreviousData: true,
    },
  );

  const {mutate: deleteTrainerWithQuery, isLoading: isTrainerDeleting} =
    useMutation(deleteUser, {
      onSuccess: data => {
        queryClient.invalidateQueries(['trainers']);
        setIsModalOpen(false);
        if (data) {
          alert('Trainer deleted successfully');
        }
      },
      onError: (error: AxiosError<{message: string}>) => {
        const errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'An unexpected error occurred.';
        alert(errorMessage);
      },
    });

  useEffect(() => {
    let prevSelectedColumns = localStorage.getItem('trainersSelectedColumns');
    if (prevSelectedColumns) {
      setTableColumns(JSON.parse(prevSelectedColumns));
    }
  }, []);

  const handleEdit = (user: any) => {
    navigate(`/addNewTrainer/${user.id}`);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (field: string) => {
    const updatedColumns = tableColumns.includes(field)
      ? tableColumns.filter(item => item !== field)
      : [...tableColumns, field];

    setTableColumns(updatedColumns);
    localStorage.setItem(
      'trainersSelectedColumns',
      JSON.stringify(updatedColumns),
    );
  };

  const handleDelete = (userId: number) => {
    setDelUserId(userId);
    setIsModalOpen(true);
  };

  if (usersLoading || isTrainerDeleting) {
    return <Loader />;
  }

  if (usersError) {
    return <div>Error while fetching users</div>;
  }
  return (
    <>
      <div className="bg-light-green h-full p-8">
        <Header title={t('trainers')} subtitle={t('clientsubheader')} />
        {/* subheader */}
        <div className="flex items-center justify-end mt-8">
          {/* right div*/}
          <div className="flex gap-4">
            <div className="flex items-center bg-white rounded-full px-3 py-2 gap-2">
              <img src={SearchSvg} alt="search" className="size-4" />
              <input
                type="text"
                onChange={e => setSearchQuery(e.target.value)}
                placeholder={t('search')}
                className="focus:border-none focus:outline-none placeholder:text-dark-text placeholder:text-[16px]"
              />
            </div>
            <div
              onClick={handleClick}
              className="flex items-center gap-2 hover:cursor-pointer">
              <img src={ColSelectSvg} alt="sort" className="size-4" />
              <p className="text-dark-text text-[16px]">{t('selectcol')}</p>
            </div>
            <div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {columns.map((option: any) => (
                  <MenuItem key={option.field}>
                    <Checkbox
                      checked={tableColumns.includes(option.field)}
                      onChange={() => handleToggle(option.field)}
                    />
                    <ListItemText primary={option.headerName} />
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        {/* clients table */}
        <div className="mt-4 h-[calc(100%-160px)]">
          <ClientsTable
            searchQuery={searchQuery}
            columns={
              columns?.filter(col => tableColumns.includes(col.field)) || []
            }
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            usersData={usersData}
          />
        </div>
      </div>

      <CustomModal
        isModalOpen={isModalOpen && !!delUserId}
        setIsModalOpen={setIsModalOpen}
        description={t('deleteTrainerNotification')}
        onClick={() => deleteTrainerWithQuery(delUserId)}
      />
    </>
  );
}

export default Index;
