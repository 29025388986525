import {Autocomplete, TextField} from '@mui/material';
import React, {useEffect} from 'react';
import {useQuery} from 'react-query';
import {fetchTrainers} from '../services/trainers';
import Loader from './Loader';
import {useTranslation} from 'react-i18next';

type Props = {
  selectedTrainerId: number | string | null;
  setSelectedTrainerId?: (val: number | string | null | any) => void;
  width?: number | string;
  borderRadius?: string;
  isShowLabel?: boolean;
};

function TrainerSelector({
  selectedTrainerId,
  setSelectedTrainerId,
  width,
  borderRadius,
  isShowLabel = true,
}: Props) {
  const {t} = useTranslation();

  const {
    data: trainersData,
    error: trainersError,
    isLoading: trainersLoading,
  } = useQuery(['trainers'], () => fetchTrainers(1, 200, ''), {
    keepPreviousData: true,
  });

  const handleTrainerChange = (
    event: React.ChangeEvent<{}>,
    newValue: any | null,
  ) => {
    if (setSelectedTrainerId) {
      setSelectedTrainerId(newValue ? newValue.id : null);
    }
  };

  if (trainersError) {
    return <div>Error while fetching trainers</div>;
  }
  if (trainersLoading) {
    return <Loader />;
  }

  return (
    <Autocomplete
      disablePortal
      size="small"
      value={
        trainersData?.data?.find(
          (trainer: any) => trainer.id === selectedTrainerId,
        ) ?? null
      }
      getOptionKey={(option: any) => option.id}
      getOptionLabel={(option: any) =>
        option ? `${option.fname} ${option.lname}` : ''
      }
      onChange={handleTrainerChange}
      options={trainersData.data ?? []}
      renderInput={params => (
        <TextField
          {...params}
          {...(isShowLabel && {label: t('trainers')})}
          sx={{
            width: width ?? 300,
            '& .MuiOutlinedInput-root': {
              borderRadius: borderRadius ?? '100px',
              border: 'none',
              backgroundColor: 'white',
            },
          }}
        />
      )}
    />
  );
}

export default TrainerSelector;
