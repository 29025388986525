import React, {useEffect, useState} from 'react';
import EditClients from './editClients';
import Header from '../../components/Header';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {
  handleClientAdd,
  handleClientEdit,
  fetchClient,
} from '../../services/clients';
import {isValidEmail} from '../../utils/validationChecks';
import {AxiosError} from 'axios';

function Index() {
  const {t} = useTranslation();
  const {userId} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedTrainerId, setSelectedTrainerId] = useState<any>(null);
  const [client, setClient] = useState({
    profile_photo: '',
    user_type: 3,
    active: 0,
    fname: '',
    lname: '',
    phone_number: '',
    email: '',
    gender: '',
    birthdate: '',
    current_weight: '',
    height: '',
    water_reminder: 0,
    food_reminder: 0,
    steps_reminder: 0,
    activity_reminder: 0,
    weight_reminder: 0,
    user_daily_goal_nutrition: '',
    user_daily_goal_water: '',
    user_daily_goal_activity: '',
    user_daily_goal_weight: '',
    user_daily_goal_steps: '',
    fitness_level: '',
    target_weight: '',
    time_to_sleep: '',
    health_need: '',
    health_issues: '',
    health_status: '',
    blood_pressure: '',
    medical_recommend: '',
    pregnant: '',
    breastfeeding: '',
    exercise_limitation: '',
    food_preferences: '',
    food_sensitivity: '',
    food_common_method: '',
    is_vegetarian: 0,
    is_vegan: 0,
    is_fruity: 0,
  });

  const {
    data: editClient,
    error: fetchError,
    isLoading: isFetching,
  } = useQuery(['client', userId], () => fetchClient(Number(userId)), {
    enabled: !!userId,
    onSuccess: data => {
      queryClient.invalidateQueries(['users']);
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  useEffect(() => {
    if (editClient) {
      setClient(editClient);
      setSelectedTrainerId(editClient?.created_by?.id ?? null);
    }
  }, [editClient]);

  const {
    mutate: addClient,
    isLoading: isAdding,
    isError: isAddError,
  } = useMutation(handleClientAdd, {
    onSuccess: () => {
      setClient({
        profile_photo: '',
        user_type: 3,
        active: 0,
        fname: '',
        lname: '',
        phone_number: '',
        email: '',
        gender: '',
        birthdate: '',
        current_weight: '',
        height: '',
        water_reminder: 0,
        food_reminder: 0,
        steps_reminder: 0,
        activity_reminder: 0,
        weight_reminder: 0,
        user_daily_goal_nutrition: '',
        user_daily_goal_water: '',
        user_daily_goal_activity: '',
        user_daily_goal_weight: '',
        user_daily_goal_steps: '',
        fitness_level: '',
        target_weight: '',
        time_to_sleep: '',
        health_need: '',
        health_issues: '',
        health_status: '',
        blood_pressure: '',
        medical_recommend: '',
        pregnant: '',
        breastfeeding: '',
        exercise_limitation: '',
        food_preferences: '',
        food_sensitivity: '',
        food_common_method: '',
        is_vegetarian: 0,
        is_vegan: 0,
        is_fruity: 0,
      });
      setSelectedTrainerId(null);
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage == 'numberExits' ? t(errorMessage) : errorMessage);
    },
  });

  const {
    mutate: editClientData,
    isLoading: isEditing,
    isError: isEditError,
  } = useMutation(handleClientEdit, {
    onSuccess: () => {
      navigate(-1);
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage == 'numberExits' ? t(errorMessage) : errorMessage);
    },
  });

  const handleSubmit = async () => {
    if (userId) {
      if (!selectedTrainerId && localStorage.getItem('user_type') === '1')
        return alert('please select trainer');

      if (client.email && !isValidEmail(client.email)) {
        return alert('Please enter valid email');
      }

      await editClientData({
        client,
        userId,
        trainer_id: selectedTrainerId ?? null,
      });
    } else {
      if (!selectedTrainerId && localStorage.getItem('user_type') === '1')
        return alert('please select trainer');
      const {
        fname,
        lname,
        phone_number,
        email,
        birthdate,
        current_weight,
        fitness_level,
        time_to_sleep,
      } = client;

      if (
        !fname ||
        !lname ||
        !phone_number ||
        !birthdate ||
        !current_weight ||
        !fitness_level ||
        !time_to_sleep
      ) {
        return alert('Please fill all required fields');
      }

      if (!isValidEmail(email)) {
        return alert('Please enter valid email');
      }

      await addClient({client, trainer_id: selectedTrainerId ?? null});
    }
  };

  if (fetchError) {
    return <div>Error while fetching client data</div>;
  }

  return (
    <div className="bg-light-green h-full p-8">
      <Header
        title={t('addeditclient')}
        subtitle={t('addclientsubheading')}
        firstBtnTxt={t('save')}
        handleSubmit={handleSubmit}
        firstBtnLoading={isAdding || isEditing || isFetching}
      />
      <EditClients
        client={client}
        setClient={setClient}
        selectedTrainerId={selectedTrainerId}
        setSelectedTrainerId={setSelectedTrainerId}
        isAddingClient
      />
    </div>
  );
}

export default Index;
