import React, {useState} from 'react';
import Header from '../../components/Header';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import * as XLSX from 'xlsx';
import {useNavigate} from 'react-router-dom';
import {useDebounce} from '../../services/debounce';
import {
  handleBulkArticleAdd,
  handleFetchArticles,
} from '../../services/articles';
import Loader from '../../components/Loader';
import {ImportSvg, ExportSvg} from '../../assets';
import {routes} from '../../constants/routes';
import {handleExport, downloadBlob} from '../../services/importAndExport';
import IconInput from '../../components/IconInput';
import TrainerSelector from '../../components/TrainerSelector';
import {AxiosError} from 'axios';
import {useTrainer} from '../../context/useTrainer';

type Props = {};

// const debounce = (func: Function, delay: number) => {
//   let timeoutId: NodeJS.Timeout;
//   return (...args: any) => {
//     clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       func(...args);
//     }, delay);
//   };
// };
function Index({}: Props) {
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [importedArticles, setImportedArticles] = useState([]);

  const {trainerId, setTrainerId} = useTrainer();
  const debouncedSearch = useDebounce(searchQuery, 300);
  const navigate = useNavigate();

  const {
    mutate: exportData,
    isLoading: exportLoading,
    isError: exportError,
  } = useMutation(handleExport, {
    onSuccess: data => {
      downloadBlob(data, 'articles_exported_file.csv');
    },
    onError: (error: AxiosError<{message: string}>) => {
      let errorMessage = '';
      if (error?.response?.status == 404) {
        errorMessage = t('noRecords');
      } else {
        errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'An unexpected error occurred.';
      }
      alert(errorMessage);
    },
  });

  const {
    data: articlesData,
    isError,
    isLoading,
  } = useQuery(
    ['articles', debouncedSearch, trainerId],
    () => handleFetchArticles(debouncedSearch, trainerId),
    {
      keepPreviousData: true,
    },
  );

  const handleArticleNavigation = (id: any) => {
    navigate(`/addNewArticle/${id}`);
  };

  const handleTitleString = (str: string) => {
    if (str.length > 80) {
      return str.substring(0, 80) + '...';
    }
    return str;
  };

  const isValidData = (jsonData: any) => {
    const keys = Object.keys(jsonData[0]);
    if (
      keys.length > 0 &&
      keys.includes('article_content') &&
      keys.includes('article_title') &&
      keys.includes('article_photo')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = async e => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, {type: 'array'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        if (isValidData(jsonData)) {
          // Validate each user before submitting
          const validArticles = jsonData.filter((article: any) => {
            if (
              article?.article_title &&
              article?.article_category &&
              article?.article_read_time &&
              article?.article_content &&
              article?.article_photo
            ) {
              if (localStorage.getItem('user_type') === '1') {
                if (article?.partner_type && article?.partner_full_name) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else {
              return false;
            }
          });

          if (validArticles.length === 0) {
            return alert('No valid article to submit.');
          }

          await bulkPlanAdd(validArticles);
        } else {
          alert('Invalid file format');
          return;
        }

        // if (!isValidData(jsonData)) {
        //   alert('Invalid file format');
        //   return;
        // }
        // //@ts-ignore
        // setImportedArticles(jsonData as any[]);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const {
    mutate: bulkPlanAdd,
    isLoading: isBulkAdding,
    isError: isBulkError,
  } = useMutation(handleBulkArticleAdd, {
    onSuccess: data => {
      queryClient.invalidateQueries(['articles']);
      if (data) {
        alert('Articles added successfully');
      }
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  if (isError) {
    return <div>Error in fetching articles</div>;
  }

  if (isLoading || isBulkAdding) {
    return <Loader />;
  }

  return (
    <div className="bg-light-green min-h-[100vh] p-8">
      <Header
        title={t('articles')}
        subtitle={t('clientsubheader')}
        firstBtnTxt={t('import')}
        firstBtnIcon={ImportSvg}
        secondBtnTxt={t('export')}
        secondBtnIcon={ExportSvg}
        handleSubmit={() => document.getElementById('xlsxUploadInput')?.click()}
        handleSubmitSecond={() => exportData({route: routes.EXPORT_ARTICLES})}
        secondBtnLoading={exportLoading}
      />
      <input
        id="xlsxUploadInput"
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
      <div className="flex items-center flex-row gap-4 justify-end mt-8">
        {localStorage.getItem('user_type') === '1' && (
          <TrainerSelector
            selectedTrainerId={trainerId}
            setSelectedTrainerId={setTrainerId}
          />
        )}
        <IconInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-4 gap-8 my-6">
        {importedArticles.length > 0
          ? importedArticles.map((article: any, index: number) => (
              <div
                key={index}
                className="flex flex-col"
                onClick={() => handleArticleNavigation(article.id)}>
                <div className="overflow-hidden h-[350px] mb-2 rounded-[8px]">
                  <img
                    src={article.article_photo}
                    className="w-full object-cover rounded-[8px] h-full"
                    alt="article image"
                  />
                </div>
                <p className="text-gray-text font-medium text-[20px]">
                  {handleTitleString(article.article_title)}
                </p>
              </div>
            ))
          : articlesData?.map((article: any) => (
              <div
                key={article.id}
                className="flex flex-col"
                onClick={() => handleArticleNavigation(article.id)}>
                <div className="overflow-hidden h-[350px] mb-2 rounded-[8px]">
                  <img
                    src={article.article_photo}
                    className="w-full object-cover rounded-[8px] h-full"
                    alt="article image"
                  />
                </div>
                <p className="text-gray-text font-medium text-[20px]">
                  {handleTitleString(article.article_title)}
                </p>
              </div>
            ))}
      </div>
    </div>
  );
}

export default Index;
