import React, {useEffect, useRef, useState} from 'react';
import Header from '../../components/Header';
import Camera from '../../assets/camera.png';
import {Article} from '../../types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {
  deleteArticle,
  handleAddArticle,
  handleArticleData,
  handleEditArticle,
} from '../../services/articles';
import Loader from '../../components/Loader';
import TrainerSelector from '../../components/TrainerSelector';
import i18n from '../../i18n';
import {MenuItem, Select} from '@mui/material';
import {AxiosError} from 'axios';
import CustomModal from '../../components/Modal';

type Props = {};
function Index({}: Props) {
  const {articleId} = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const inputImgRef = useRef(null);
  const [article, setArticle] = useState<Article>({
    article_title: '',
    article_category: '',
    article_read_time: '',
    article_content: '',
    article_photo: '',
    partner_type: null,
    partner_full_name: '',
  });
  const [selectedTrainerId, setSelectedTrainerId] = useState<any>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const quillRefArticle = useRef<any>(null);

  const partnerType = [
    {
      id: 1,
      label: t('nutritionist'),
      key: 1,
    },
    {
      id: 2,
      label: t('trainer'),
      key: 2,
    },
  ];

  const {mutate: addArticle, isLoading: isAddLoading} = useMutation(
    handleAddArticle,
    {
      onSuccess: data => {
        setArticle({
          article_title: '',
          article_category: '',
          article_read_time: '',
          article_content: '',
          article_photo: '',
          partner_type: null,
          partner_full_name: '',
        });
        setSelectedTrainerId(null);
      },
      onError: (error: AxiosError<{message: string}>) => {
        const errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'An unexpected error occurred.';
        alert(errorMessage);
      },
    },
  );

  const {mutate: editArticle, isLoading: isEditLoading} = useMutation(
    handleEditArticle,
    {
      onSuccess: data => {
        setArticle({
          article_title: '',
          article_category: '',
          article_read_time: '',
          article_content: '',
          article_photo: '',
          partner_type: null,
          partner_full_name: '',
        });
        if (data.data) alert('Article edited successfully');
        navigate(-1);
      },
      onError: (error: AxiosError<{message: string}>) => {
        const errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'An unexpected error occurred.';
        alert(errorMessage);
      },
    },
  );

  const {mutate: deleteArticleWithQuery, isLoading: isDeleting} = useMutation(
    deleteArticle,
    {
      onSuccess: data => {
        setIsModalOpen(false);
        if (data) {
          alert('Article deleted successfully');
        }
        navigate(-1);
      },
      onError: (error: AxiosError<{message: string}>) => {
        const errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'An unexpected error occurred.';
        alert(errorMessage);
      },
    },
  );

  const {
    data: articleData,
    isError: isArticleError,
    isLoading: isArticleLoading,
  } = useQuery(['article', articleId], () => handleArticleData(articleId), {
    enabled: !!articleId,
    onSuccess: data => {
      queryClient.invalidateQueries(['articles']);
    },
    onError: err => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (quillRefArticle.current) {
      const quill = quillRefArticle.current.editor;
      const direction = i18n.language === 'he' ? 'rtl' : 'ltr';
      const root = quill.root;

      // Set the direction and alignment for the text
      root.style.direction = direction;
      root.style.textAlign = i18n.language === 'he' ? 'right' : 'left';
    }
  }, [i18n.language, articleData]);

  useEffect(() => {
    if (!articleData) return;
    setArticle({
      article_title: articleData.article_title ?? '',
      article_category: articleData.article_category ?? '',
      article_read_time: articleData.article_read_time ?? '',
      article_content: articleData.article_content ?? '',
      partner_type: articleData.partner_type ?? null,
      partner_full_name: articleData.partner_full_name ?? '',
      article_photo: articleData.article_photo ?? '',
    });
    setSelectedTrainerId(articleData.created_by?.id ?? null);
  }, [articleData]);

  const handleImg = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const file = event.target.files[0];
    if (!file || file.size > 300 * 1024) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      setArticle((prev: any) => ({
        ...prev,
        article_photo: base64String,
      }));
    };
    reader.readAsDataURL(file);
  };

  const imgSelector = () => {
    // @ts-ignore
    if (inputImgRef.current) inputImgRef.current?.click();
  };

  const handleChange = (key: string, value: string | number) => {
    setArticle((prev: any) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleSubmit = async () => {
    try {
      if (articleId) {
        await editArticle({
          article,
          id: articleId,
        });
      } else {
        const {
          article_title,
          article_category,
          article_read_time,
          partner_type,
          partner_full_name,
          article_content,
          article_photo,
        } = article;

        if (localStorage.getItem('user_type') === '1') {
          if (!selectedTrainerId || !partner_type || !partner_full_name)
            return alert('All fields are required');
        }

        if (
          !article_title ||
          !article_category ||
          !article_read_time ||
          !article_content ||
          !article_photo
        ) {
          return alert('All fields are required');
        }
        await addArticle({article, id: selectedTrainerId});
      }
    } catch (error) {
      console.log('Submission error:', error);
    }
  };

  const handleFocus = () => {
    if (quillRefArticle.current) {
      quillRefArticle.current.focus();
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{align: []}],
      [{list: 'ordered'}],
      [{direction: 'rtl'}],
      ['link', 'image'],
      ['emoji'],
    ],
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'align',
    'list',
    'direction',
    'link',
    'image',
    'emoji',
  ];

  if (isArticleError) {
    return <div>Error occurred while fetching article data</div>;
  }

  if (isArticleLoading) return <Loader />;

  return (
    <>
      <div className="bg-light-green h-full p-8">
        <Header
          title={t('articleedit')}
          subtitle={t('articlesubheading')}
          firstBtnTxt={t('save')}
          handleSubmit={handleSubmit}
          firstBtnLoading={isAddLoading || isEditLoading || isDeleting}
          showDeleteIcon={Boolean(articleId)}
          handleDeleteIconClick={() => {
            setIsModalOpen(true);
          }}
        />

        <div className="border border-border-color rounded-[15px] mt-4 p-6 h-[calc(100%-90px)] overflow-auto grid grid-cols-12 gap-2 ">
          <div className="col-span-9 p-4">
            <div>
              <p className="text-[20px] font-medium mb-2">
                {t('articletitle')}
              </p>
              <input
                type="text"
                name="article_title"
                value={article?.article_title ?? ''}
                onChange={e => handleChange('article_title', e.target.value)}
                className="w-full h-[80px] rounded-[12px]  border-[1px] border-border-color px-4 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
              />
            </div>

            <div className="mt-4 w-full flex items-center gap-5">
              <div className="flex-1">
                <p className="text-[20px] font-medium mb-2">{t('category')}</p>
                <input
                  type="text"
                  name="article_category"
                  value={article?.article_category ?? ''}
                  onChange={e =>
                    handleChange('article_category', e.target.value)
                  }
                  className="w-full h-[80px] rounded-[12px]  border-[1px] border-border-color px-4 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                />
              </div>

              <div className="flex-1">
                <p className="text-[20px] font-medium mb-2">
                  {t('readingtime')}
                </p>
                <input
                  type="text"
                  name="article_read_time"
                  value={article?.article_read_time ?? ''}
                  onChange={e => {
                    const value = e.target.value;
                    if (!value) {
                      handleChange('article_read_time', Number(value));
                    } else if (
                      typeof value === 'string' &&
                      !isNaN(parseInt(value))
                    ) {
                      handleChange('article_read_time', Number(value));
                    }
                  }}
                  className="w-full h-[80px] rounded-[12px]  border-[1px] border-border-color px-4 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                />
              </div>
            </div>

            <div>
              <p className="text-[20px] font-medium mt-4 mb-2">
                {t('content')}
              </p>
              <div onClick={handleFocus}>
                <ReactQuill
                  ref={quillRefArticle}
                  theme="snow"
                  placeholder="Enter content"
                  style={{
                    backgroundColor: 'white',
                  }}
                  modules={modules}
                  formats={formats}
                  value={article.article_content ?? ''}
                  onChange={value => handleChange('article_content', value)}
                  className="w-full  rounded-[12px]  border-[1px] border-border-color px-1 py-1 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3 p-4 flex flex-col gap-8">
            {localStorage.getItem('user_type') === '1' && (
              <div className="w-full flex flex-col gap-4">
                <TrainerSelector
                  selectedTrainerId={selectedTrainerId}
                  setSelectedTrainerId={setSelectedTrainerId}
                  width={'100%'}
                  borderRadius="8px"
                />

                <div className="w-full">
                  <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                    {t('partnerType')}
                  </p>
                  <Select
                    sx={{
                      width: '100%',
                      height: '40px',
                      borderRadius: '12px',
                      backgroundColor: '#ffffff',
                      border: '1px solid #E7E7E7',
                      fontSize: '12px',
                      lineHeight: '16px',
                      outline: 'none',
                    }}
                    name="partner_type"
                    value={article?.partner_type ?? ''}
                    onChange={e =>
                      handleChange('partner_type', Number(e.target.value))
                    }>
                    {partnerType?.map((option: any) => (
                      <MenuItem
                        key={option?.id}
                        sx={{fontSize: '12px', lineHeight: '16px'}}
                        value={option?.key}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="w-full">
                  <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                    {t('partnerFullName')}
                  </p>
                  <input
                    type="text"
                    name="partner_full_name"
                    value={article?.partner_full_name ?? ''}
                    onChange={e =>
                      handleChange('partner_full_name', e.target.value)
                    }
                    className="w-full h-[40px] rounded-[12px]  border-[1px] border-border-color px-4 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                  />
                </div>
              </div>
            )}

            <div>
              <p className="text-[20px] font-medium mb-2">
                {t('articleImage')}
              </p>
              <div
                onClick={imgSelector}
                className="bg-imgHolder-bg h-[350px] w-full overflow-hidden flex items-center justify-center rounded-[8px]">
                {article.article_photo ? (
                  <img
                    src={article.article_photo}
                    alt="img"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="h-full w-full flex items-center justify-center">
                    <div className="size-[80px] flex items-center justify-center rounded-full bg-gray-500">
                      <img src={Camera} alt="camera" className="size-[14px]" />
                    </div>
                  </div>
                )}
              </div>
              <input
                ref={inputImgRef}
                className="hidden"
                type="file"
                onChange={handleImg}
                accept=".png, .jpg, .jpeg"
              />
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        description={t('deleteArticleNotification')}
        onClick={() => deleteArticleWithQuery(articleId)}
      />
    </>
  );
}

export default Index;
