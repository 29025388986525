import React, {useCallback, useEffect, useState} from 'react';
import Header from '../../components/Header';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {Checkbox, ListItemText, Menu, MenuItem} from '@mui/material';
import * as XLSX from 'xlsx';
import ClientsTable from './clientsTable';
import {useTranslation} from 'react-i18next';
import {ClientSubHeader} from '../../types';
import Move from '../../assets/clientMove.svg';
import Water from '../../assets/clientWater.svg';
import Nutrition from '../../assets/clientNutrition.svg';
import Weight from '../../assets/clientWeight.svg';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import {
  deleteUser,
  fetchUserStatsAverage,
  fetchUsers,
  handleBulkClientAdd,
  handlePlanAssociation,
} from '../../services/clients';
import Loader from '../../components/Loader';
import {
  ImportSvg,
  ExportSvg,
  SearchSvg,
  ColSelectSvg,
  EditSvg,
  BasketSvg,
  DashboardSvg,
} from '../../assets';
import {handleExport, downloadBlob} from '../../services/importAndExport';
import {routes} from '../../constants/routes';
import {useDebounce} from '../../services/debounce';
import DashboardModal from './DashboardModal';
import {handleFetchPlans} from '../../services/plans';
import i18n from '../../i18n';
import IconInput from '../../components/IconInput';
import TrainerSelector from '../../components/TrainerSelector';
import {AxiosError} from 'axios';
import {useTrainer} from '../../context/useTrainer';

type Props = {};

function Index({}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [clientsSubHeaders, setClientsSubHeaders] = useState([
    {
      id: 1,
      key: 'averageSteps',
      label: t('averageSteps'),
      header: '1,578',
      icon: Move,
    },
    {
      id: 2,
      key: 'water',
      label: t('averageWater'),
      header: '8',
      icon: Water,
    },
    {
      id: 3,
      key: 'nutrition',
      label: t('averageNutrition'),
      header: '1,578',
      icon: Nutrition,
    },
    {
      id: 4,
      key: 'weight',
      label: t('averageWeight'),
      header: '81',
      icon: Weight,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'profile_photo',
      headerName: t('image'),
      sortable: false,
      // disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      width: 70,
      minWidth: 70,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams<any>) => {
        const userImg = () => {
          const defaultImageUrl =
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdpRvftRBgfCbvzOHB0bANVih3QvZD-xZ4flbABUFGDctmaY87ajkJD5RhdvVcyZvkS7U&usqp=CAU';

          if (!params.value) {
            return defaultImageUrl;
          }

          if (params.value.startsWith('https:')) {
            const imageBaseUrl = 'https://scaleapp-images.s3.amazonaws.com';
            const imageUrl = `${imageBaseUrl}/${params.value.split('/').pop()}`;
            return imageUrl;
          }

          return params.value;
        };

        return (
          <div className="flex items-center justify-center">
            <div className="rounded-full overflow-hidden w-10 h-10">
              <img
                src={userImg()}
                alt="img"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        );
      },
    },
    {
      field: 'fname',
      headerName: t('firstname'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'lname',
      headerName: t('lastname'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'phone_number',
      headerName: t('phone'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'age',
      headerName: t('age'),
      width: 80,
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'current_weight',
      headerName: t('currentweight'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'nutrition_plan',
      headerName: t('planAssigned'),
      width: 150,
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<any>) =>
        params.value ? (
          <div className="text-center border border-border-color rounded-full">
            <p className="text-xs font-[400] py-1 px-8">
              {params.value.plan_name ?? ''}
            </p>
          </div>
        ) : (
          ''
        ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 150,
      minWidth: 150,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="flex justify-center items-center gap-4">
          <img
            src={DashboardSvg}
            alt="Dashboard"
            onClick={() => handleAssociation(params.row.id)}
            className="cursor-pointer"
          />
          <img
            src={EditSvg}
            alt="Edit"
            onClick={() => handleEdit(params.row)}
            className="cursor-pointer"
          />
          <img
            src={BasketSvg}
            alt="Delete"
            onClick={() => handleDelete(params.row.id)}
            className="cursor-pointer"
          />
        </div>
      ),
    },
  ];
  const [tableColumns, setTableColumns] = useState(
    columns.map(col => col.field),
  );

  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [delUserId, setDelUserId] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [nutrionistPlan, setNutrionistPlan] = useState<any>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>({
    id: null,
    plan_name: 'Select Plan',
  });
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isAssociateModalOpen, setIsAssociateModalOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  const {trainerId, setTrainerId} = useTrainer();
  const debouncedSearch = useDebounce(searchQuery, 300);

  const {data: userStatsAverageData, isLoading: isLoadingUserStatsAverageData} =
    useQuery(
      ['userStatsAverageData', trainerId],
      () => fetchUserStatsAverage(trainerId),
      {
        keepPreviousData: true,
      },
    );

  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useQuery(
    ['users', page, pageSize, debouncedSearch, trainerId],
    () => fetchUsers(page, pageSize, debouncedSearch, trainerId),
    {
      keepPreviousData: true,
    },
  );

  const {
    mutate: deleteWithQuery,
    isLoading: isDeleting,
    isError,
  } = useMutation(deleteUser, {
    onSuccess: data => {
      queryClient.invalidateQueries(['users']);
      setIsModalOpen(false);
      if (data) {
        alert('User deleted successfully');
      }
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  const {
    data: plans,
    isLoading: isLoadingPlans,
    isError: isPlanError,
  } = useQuery(['nutritionistPlans'], () => handleFetchPlans(1, 100, ''), {
    keepPreviousData: true,
    onSuccess: data => {
      if (data.data) {
        setNutrionistPlan(data.data);
      }
    },
  });

  const {
    mutate: exportData,
    isLoading: isExporting,
    isError: isExportError,
  } = useMutation(handleExport, {
    onSuccess: data => {
      downloadBlob(data, 'users_exported_file.csv');
    },
    onError: (error: AxiosError<{message: string}>) => {
      let errorMessage = '';
      if (error?.response?.status == 404) {
        errorMessage = t('noRecords');
      } else {
        errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'An unexpected error occurred.';
      }
      alert(errorMessage);
    },
  });

  const {
    mutate: bulkUserAdd,
    isLoading: isBulkAdding,
    isError: isBulkError,
  } = useMutation(handleBulkClientAdd, {
    onSuccess: data => {
      queryClient.invalidateQueries(['users']);
      if (data) {
        alert('Clients added successfully');
      }
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(
        errorMessage == 'numberExits' ? t('numberExitsBulk') : errorMessage,
      );
    },
  });

  const {
    mutate: associateUser,
    isLoading: isAssociating,
    isError: isAssociateError,
  } = useMutation(handlePlanAssociation, {
    onSuccess: data => {
      queryClient.invalidateQueries(['users']);
      if (data) {
        setSelectedPlan(null);
        setSelectedUser(null);
        alert('User Associated successfully');
      }
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  useEffect(() => {
    let prevSelectedColumns = localStorage.getItem('clientsSelectedColumns');
    if (prevSelectedColumns) {
      setTableColumns(JSON.parse(prevSelectedColumns));
    }
  }, []);

  useEffect(() => {
    let prevSubHeaders = [...clientsSubHeaders];
    let newSubHeaders = prevSubHeaders.map((item: any) => {
      if (item.id == 1) {
        return {
          ...item,
          label: t('averageSteps'),
          header: Math.round(userStatsAverageData?.average_steps) || 0,
        };
      } else if (item.id == 2) {
        return {
          ...item,
          label: t('averageWater'),
          header: Math.round(userStatsAverageData?.average_water) || 0,
        };
      } else if (item.id == 3) {
        return {
          ...item,
          label: t('averageNutrition'),
          header: Math.round(userStatsAverageData?.average_calories) || 0,
        };
      } else {
        return {
          ...item,
          label: t('averageWeight'),
          header: Math.round(userStatsAverageData?.average_weight) || 0,
        };
      }
    });
    setClientsSubHeaders(newSubHeaders);
  }, [t, userStatsAverageData]);

  const handleEdit = (user: any) => {
    navigate(`/addNewClient/${user.id}`);
  };
  const handleDelete = (userId: number) => {
    setDelUserId(userId);
    setIsModalOpen(true);
  };

  const handleAssociation = (userId: number) => {
    setIsAssociateModalOpen(true);
    setSelectedUser(userId);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (field: string) => {
    const updatedColumns = tableColumns.includes(field)
      ? tableColumns.filter(item => item !== field)
      : [...tableColumns, field];

    setTableColumns(updatedColumns);
    localStorage.setItem(
      'clientsSelectedColumns',
      JSON.stringify(updatedColumns),
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async e => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, {type: 'array'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const validUsers = jsonData
          .filter((user: any) => {
            if (
              user?.fname &&
              user?.lname &&
              user?.phone_number &&
              user?.birthdate &&
              user?.current_weight &&
              user?.fitness_level &&
              user?.time_to_sleep
            ) {
              return true;
            } else {
              return false;
            }
          })
          ?.map((user: any) => {
            const {created_by, nutrition_plan, birthdate, ...filteredClient} =
              user;
            return {
              ...filteredClient,
              birthdate: new Date(user.birthdate)?.toISOString(),
            };
          });

        if (validUsers.length === 0) {
          return alert('No valid clients to submit.');
        }

        await bulkUserAdd(validUsers);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handlePlanChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: any | null) => {
      setSelectedPlan(newValue ? newValue : null);
    },
    [setSelectedPlan],
  );

  const associateUserWithPlan = async () => {
    try {
      const userId = selectedUser ?? 0;
      const selectedPlanId = selectedPlan?.id ?? 0;

      if (!selectedPlanId || !userId) {
        return;
      }

      await associateUser({userId, selectedPlanId});
    } catch (error) {
      console.error('Error associating user with plan:', error);
      alert('Error in associating user with plan');
    }
  };

  if (
    isDeleting ||
    usersLoading ||
    isLoadingPlans ||
    isAssociating ||
    isBulkAdding ||
    isLoadingUserStatsAverageData
  ) {
    return <Loader />;
  }

  if (usersError || isPlanError) {
    return <div>Error while fetching clients</div>;
  }

  return (
    <>
      <div className="bg-light-green h-full p-8">
        <Header
          title={t('clientheader')}
          subtitle={t('clientsubheader')}
          firstBtnTxt={t('import')}
          firstBtnIcon={ImportSvg}
          secondBtnTxt={t('export')}
          secondBtnIcon={ExportSvg}
          handleSubmitSecond={() => exportData({route: routes.EXPORT_CLIENTS})}
          handleSubmit={() =>
            document.getElementById('xlsxUploadInput')?.click()
          }
          secondBtnLoading={isExporting}
        />
        <input
          id="xlsxUploadInput"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{display: 'none'}}
        />
        {/* subheader */}
        <div className="flex flex-wrap items-center justify-between gap-3 mt-8">
          {/* left div */}
          <div className="flex gap-3">
            {clientsSubHeaders?.map(client => (
              <div
                key={client.key}
                className={`bg-white flex gap-2 ${
                  i18n.language == 'en' ? 'pr-6' : 'pl-6'
                } items-center rounded-[12px] box-border py-2`}>
                <img
                  src={client.icon}
                  alt={client.key}
                  className="-mt-4 object-contain"
                />
                <div className="flex flex-col  bg-white">
                  <p className="font-[700] text-[13px] text-dark-text">
                    {client?.header}
                  </p>
                  <p className="font-[400] text-sm text-gray-text">
                    {client?.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* right div*/}
          <div className="flex gap-4">
            {localStorage.getItem('user_type') === '1' && (
              <TrainerSelector
                selectedTrainerId={trainerId}
                setSelectedTrainerId={setTrainerId}
              />
            )}
            <IconInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />

            <div
              onClick={handleClick}
              className="flex items-center gap-2 hover:cursor-pointer">
              <img src={ColSelectSvg} alt="sort" className="size-4" />
              <p className="text-dark-text text-[16px]">{t('selectcol')}</p>
            </div>
            <div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {columns.map((option: any) => (
                  <MenuItem key={option.field}>
                    <Checkbox
                      checked={tableColumns.includes(option.field)}
                      onChange={() => handleToggle(option?.field)}
                    />
                    <ListItemText primary={option.headerName} />
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        {/* clients table */}
        <div className="mt-4 h-[calc(100%-220px)]">
          <ClientsTable
            searchQuery={searchQuery}
            columns={columns.filter(col => tableColumns.includes(col.field))}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            deleteWithQuery={deleteWithQuery}
            delUserId={delUserId}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            usersData={usersData}
          />
        </div>
      </div>
      <DashboardModal
        isModalOpen={isAssociateModalOpen}
        setIsModalOpen={setIsAssociateModalOpen}
        plans={nutrionistPlan}
        handlePlanChange={handlePlanChange}
        selectedPlan={selectedPlan}
        onClick={() => associateUserWithPlan()}
      />
    </>
  );
}

export default Index;
