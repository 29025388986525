import {routes} from '../../constants/routes';
import {api} from '../axiosInstance';

export const handleFetchFoodIngredients = async (
  page: number,
  pageSize: number,
  debouncedSearch: string,
  selectedTrainerId: number | string | null = null,
) => {
  const response = await api.get(
    `${
      routes.FOOD_INGREDIENTS
    }?page=${page}&limit=${pageSize}&search=${debouncedSearch}&sortKey=item_name&sortDirection=ASC&trainer_id=${
      selectedTrainerId !== null ? Number(selectedTrainerId) : ''
    }`,
  );
  return response.data;
};

export const handleFoodIngredientDelete = async (foodItem: any) => {
  const response = await api.delete(
    `${routes.FOOD_INGREDIENTS}/${Number(foodItem)}`,
  );
  return response.data;
};

export const handleFoodIngredientAdding = async ({
  foodIngredient,
}: // trainer_id,
any) => {
  // const {
  //   item_name,
  //   item_calories_per100g,
  //   item_fat_per100g,
  //   item_protein_per100g,
  // } = foodIngredient;
  // if (
  //   item_name === '' ||
  //   item_calories_per100g === '' ||
  //   item_fat_per100g === '' ||
  //   item_protein_per100g === '' ||
  //   trainer_id === '' ||
  //   trainer_id === null
  // ) {
  //   return alert('Please fill all the required fields');
  // }
  const response = await api.post(`${routes.FOOD_INGREDIENTS}`, {
    ingredients: [{...foodIngredient}],
    // trainer_id: Number(trainer_id),
  });
  return response.data;
};

export const handleBulkFoodIngredientAdd = async (foodIngredients: any) => {
  const response = await api.post(`${routes.FOOD_INGREDIENTS}`, {
    ingredients: foodIngredients,
    // trainer_id: Number(trainer_id),
  });
  return response.data;
};

export const handleGetFoodItem = async (foodId: any) => {
  const response = await api.get(`${routes.FOOD_INGREDIENTS}/${foodId}`);
  return response.data;
};

export const handleEditFoodItem = async ({foodIngredient, foodId}: any) => {
  const {
    item_name,
    item_calories_per100g,
    item_fat_per100g,
    item_protein_per100g,
  } = foodIngredient;
  if (
    item_name === '' ||
    item_calories_per100g === '' ||
    item_fat_per100g === '' ||
    item_protein_per100g === '' ||
    foodId === ''
  ) {
    return alert('Please fill all the required fields');
  }
  const response = await api.put(`${routes.FOOD_INGREDIENTS}/${foodId}`, {
    ...foodIngredient,
  });
  return response.data;
};
