import React, {useEffect, useState} from 'react';
import Header from '../../components/Header';
import EditNutrisionistPlans from './editNutrisionistPlans';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {
  handlePlansAdding,
  handleGetPlan,
  handleEditPlan,
} from '../../services/plans';
import {handleFetchFoodIngredients} from '../../services/foodIngredients';
import {AxiosError} from 'axios';
type Props = {};

const validateAge = (age: any[]) => {
  if (age.length === 0) {
    return false;
  }
  const {min, max} = age[0] || {};
  if (Number(min) >= 0 && Number(max) >= 0) {
    return true;
  }
  return false;
};

function Index({}: Props) {
  const {t} = useTranslation();
  const {planId} = useParams<{planId: string}>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [selectedTrainerId, setSelectedTrainerId] = useState<any>(null);
  const [foodIngredients, setFoodIngredients] = useState([]);
  const [nutritionist, setNutritionist] = useState({
    plan_tags: [],
    plan_name: '',
    plan_notes: '',
    plan_creation_date: '',
    plan_type: [],
    plan_gender: [],
    plan_ages: [],
    eating_times: [],
  });

  const {
    mutate: addPlan,
    isLoading: isAdding,
    isError: isAddError,
  } = useMutation(handlePlansAdding, {
    onSuccess: () => {
      queryClient.invalidateQueries(['nutritionistPlans']);
      setNutritionist({
        plan_tags: [],
        plan_name: '',
        plan_notes: '',
        plan_creation_date: '',
        plan_type: [],
        plan_gender: [],
        plan_ages: [],
        eating_times: [],
      });
      setSelectedTrainerId(null);
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  const {
    data: plan,
    isLoading: isPlanLoading,
    isError: isPlanError,
  } = useQuery(['nutritionistPlan', planId], () => handleGetPlan(planId), {
    enabled: !!planId,
  });

  const {
    mutate: editPlan,
    isLoading: isEditLoading,
    isError: isEditError,
  } = useMutation(handleEditPlan, {
    onSuccess: data => {
      queryClient.invalidateQueries(['nutritionistPlans']);
      if (data) {
        alert('Plan updated successfully');
      }
      navigate(-1);
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  const {
    data: foodItemsData,
    error: foodItemsError,
    isLoading: foodItemsLoading,
  } = useQuery(['foodItems'], () => handleFetchFoodIngredients(1, 5000, ''), {
    keepPreviousData: true,
    onSuccess: data => {
      if (data.data.length > 0) {
        setFoodIngredients(data.data);
      }
    },
    onError: error => {
      alert(error);
      console.log('food items error is ====>>>>>', error);
    },
  });

  useEffect(() => {
    if (plan) {
      setNutritionist(prevState => ({
        ...prevState,
        plan_tags: plan.plan_tags ?? [],
        plan_name: plan.plan_name ?? '',
        plan_notes: plan.plan_notes ?? '',
        plan_creation_date: plan.plan_creation_date ?? '',
        plan_type: plan.plan_type ?? [],
        plan_gender: plan.plan_gender ?? [],
        plan_ages: plan.plan_ages ?? [],
        eating_times: plan.eating_times ?? [],
      }));
      // setSelectedTrainerId(plan.created_by?._id ?? null);
    }
  }, [plan]);

  const handleSubmit = async () => {
    if (planId) {
      if (!validateAge(nutritionist.plan_ages)) {
        return alert('Please enter valid age range');
      }
      await editPlan({plan: nutritionist, planId});
    } else {
      if (!selectedTrainerId && localStorage.getItem('user_type') === '1')
        return alert('please select trainer');
      const {plan_name, plan_gender, plan_ages, plan_tags} = nutritionist;
      if (
        plan_name === '' ||
        plan_gender.length === 0 ||
        plan_ages.length === 0 ||
        plan_tags.length === 0
      ) {
        return alert('Please fill all the required fields');
      }
      if (!validateAge(plan_ages)) {
        return alert('Please enter valid age range');
      }
      await addPlan({
        plan: nutritionist,
        trainer_id: selectedTrainerId ?? null,
      });
    }
  };

  if (isPlanError) {
    return <div>Error while fetching nutrition plan data</div>;
  }

  return (
    <div className="bg-light-green h-full p-8">
      <Header
        title={t('addnutrisionistplan')}
        subtitle={t('addnutrisionistplanssubheadings')}
        firstBtnTxt={t('save')}
        handleSubmit={handleSubmit}
        firstBtnLoading={isAdding || isEditLoading || isPlanLoading}
      />
      <EditNutrisionistPlans
        nutritionist={nutritionist}
        setNutritionist={setNutritionist}
        setSelectedTrainerId={setSelectedTrainerId}
        selectedTrainerId={selectedTrainerId}
        foodIngredients={foodIngredients}
      />
    </div>
  );
}

export default Index;
