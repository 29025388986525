import React, {useMemo} from 'react';
import {
  DataGrid,
  GridColDef,
  gridClasses,
  GridRowSpacingParams,
} from '@mui/x-data-grid';
import {Pagination} from '@mui/material';
import {styled} from '@mui/material/styles';
import CustomModal from '../../components/Modal';
import {useTranslation} from 'react-i18next';

type Props = {
  columns: GridColDef[];
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  deletePlan: (id: string) => void;
  delPlanId: any;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize?: (pageSize: number) => void;
  plans: any;
};
// const debounce = (func: Function, delay: number) => {
//   let timeoutId: NodeJS.Timeout;
//   return (...args: any) => {
//     clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       func(...args);
//     }, delay);
//   };
// };

const CustomPagination = styled(Pagination)(({theme}) => ({
  '& .MuiPaginationItem-root': {
    border: 'none',
  },
  '& .MuiPaginationItem-previousNext': {
    border: '1px solid #e0e0e0',
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    color: 'white',
  },
  '& .MuiPaginationItem-root.Mui-selected:hover': {
    color: 'white',
  },
  '& .MuiPaginationItem-root:hover': {
    color: 'white',
  },
}));

function NutrisionistTable({
  columns,
  isModalOpen,
  setIsModalOpen,
  deletePlan,
  delPlanId,
  page,
  setPage,
  pageSize,
  plans,
}: Props) {
  const {t, i18n} = useTranslation();
  const nutrisionistPlans = plans?.data || [];

  const startingIndex = useMemo(() => {
    return page * pageSize - pageSize + 1;
  }, [page, pageSize]);

  const endIndex = useMemo(() => {
    return Math.min(page * pageSize, plans?.total ?? 0);
  }, [page, pageSize, plans?.total]);

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const CustomFooter = () => {
    return (
      <div className="flex justify-between items-center py-4 px-2">
        <div>
          <p className="text-xs ">
            {i18n.language === 'he'
              ? `מציג ${startingIndex ?? 0} עד ${endIndex ?? 0} מתוך ${
                  plans.total ?? 0
                } פריטים`
              : `Showing ${startingIndex ?? 0} to ${endIndex ?? 0} of ${
                  plans.total ?? 0
                } items`}
          </p>
        </div>
        <CustomPagination
          count={plans?.totalPages ?? 0}
          page={plans?.page ?? 0}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    );
  };

  return (
    <>
      <div className="w-full h-full">
        <DataGrid
          sx={{
            '& .MuiDataGrid-row': {
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#E7E7E7',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
            },
            paddingInline: '20px',
            '--DataGrid-rowBorderColor': 'transparent',
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '6px'},
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '18px',
            },
            [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
              outline: 'transparent',
            },
            [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
              {
                outline: 'none',
              },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#f4fef7',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '13px',
              fontWeight: 700,
              color: '#343434',
            },
            '& .MuiDataGrid-columnHeaders .MuiDataGrid-scrollbarFiller': {
              backgroundColor: '#f4fef7',
            },
          }}
          rows={nutrisionistPlans}
          columns={columns}
          disableRowSelectionOnClick
          disableColumnMenu
          getRowHeight={() => 'auto'}
          paginationMode="server"
          // filterMode="server"
          getRowSpacing={getRowSpacing}
          slots={{
            footer: CustomFooter,
          }}
        />
      </div>
      <CustomModal
        onClick={() => deletePlan(delPlanId)}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        description={t('deletePlanNotification')}
      />
    </>
  );
}

export default NutrisionistTable;
