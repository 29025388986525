import React, {useEffect, useState} from 'react';
import EditClients from '../editClients/editClients';
import Header from '../../components/Header';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {
  handleTrainerAdd,
  handleTrainerEdit,
  fetchTrainer,
} from '../../services/trainers';
import {AxiosError} from 'axios';

function Index() {
  const {t} = useTranslation();
  const {userId} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [trainer, setTrainer] = useState({
    profile_photo: '',
    user_type: 2,
    active: 0,
    fname: '',
    lname: '',
    phone_number: '',
    email: '',
    gender: '',
    birthdate: '',
    current_weight: '',
    height: '',
    water_reminder: 0,
    food_reminder: 0,
    steps_reminder: 0,
    activity_reminder: 0,
    weight_reminder: 0,
    user_daily_goal_nutrition: '',
    user_daily_goal_water: '',
    user_daily_goal_activity: '',
    user_daily_goal_weight: '',
    user_daily_goal_steps: '',
    fitness_level: '',
    target_weight: '',
    time_to_sleep: '',
    health_need: '',
    health_issues: '',
    health_status: '',
    blood_pressure: '',
    medical_recommend: '',
    pregnant: '',
    breastfeeding: '',
    exercise_limitation: '',
    food_preferences: '',
    food_sensitivity: '',
    food_common_method: '',
    is_vegetarian: 0,
    is_vegan: 0,
    is_fruity: 0,
  });

  const {
    data: editTrainer,
    error: fetchError,
    isLoading: isFetching,
  } = useQuery(['trainer', userId], () => fetchTrainer(Number(userId)), {
    enabled: !!userId,
    onSuccess: data => {
      console.log('edited successfully');
      queryClient.invalidateQueries(['trainers']);
    },
  });

  console.log('the trainer that would be editted is', editTrainer);
  useEffect(() => {
    if (editTrainer) {
      setTrainer({
        ...editTrainer,
        phone_number: editTrainer.phone_number.replace(/\+/g, ''), // Remove the '+' character
      });
    }
  }, [editTrainer]);
  console.log('the trainer that would be editted is ++++', trainer);
  const {
    mutate: addTrainer,
    isLoading: isAdding,
    isError: isAddError,
  } = useMutation(handleTrainerAdd, {
    onSuccess: () => {
      setTrainer({
        profile_photo: '',
        user_type: 2,
        active: 0,
        fname: '',
        lname: '',
        phone_number: '',
        email: '',
        gender: '',
        birthdate: '',
        current_weight: '',
        height: '',
        water_reminder: 0,
        food_reminder: 0,
        steps_reminder: 0,
        activity_reminder: 0,
        weight_reminder: 0,
        user_daily_goal_nutrition: '',
        user_daily_goal_water: '',
        user_daily_goal_activity: '',
        user_daily_goal_weight: '',
        user_daily_goal_steps: '',
        fitness_level: '',
        target_weight: '',
        time_to_sleep: '',
        health_need: '',
        health_issues: '',
        health_status: '',
        blood_pressure: '',
        medical_recommend: '',
        pregnant: '',
        breastfeeding: '',
        exercise_limitation: '',
        food_preferences: '',
        food_sensitivity: '',
        food_common_method: '',
        is_vegetarian: 0,
        is_vegan: 0,
        is_fruity: 0,
      });
      console.log('Trainer added successfully');
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  const {
    mutate: editTrainerData,
    isLoading: isEditing,
    isError: isEditError,
  } = useMutation(handleTrainerEdit, {
    onSuccess: () => {
      console.log('Trainer edited successfully');
      navigate(-1);
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  const handleSubmit = async () => {
    if (userId) {
      await editTrainerData({trainer, userId});
    } else {
      const {
        fname,
        lname,
        phone_number,
        birthdate,
        current_weight,
        fitness_level,
        time_to_sleep,
      } = trainer;
      if (
        !fname ||
        !lname ||
        !phone_number ||
        !birthdate ||
        !current_weight ||
        !fitness_level ||
        !time_to_sleep
      ) {
        return alert('Please fill all required fields');
      }
      await addTrainer(trainer);
    }
  };

  if (fetchError) {
    return <div>Error while fetching trainer data</div>;
  }
  return (
    <div className="bg-light-green h-full p-8">
      <Header
        title={t('addEditTrainer')}
        subtitle={t('addclientsubheading')}
        firstBtnTxt={t('save')}
        handleSubmit={handleSubmit}
        firstBtnLoading={isAdding || isEditing}
      />
      <EditClients client={trainer} setClient={setTrainer} />
    </div>
  );
}

export default Index;
