import {routes} from '../../constants/routes';
import {api} from '../axiosInstance';
import {Recipe} from '../../types';
export const handleFetchRecipes = async (
  page: number,
  pageSize: number,
  debouncedSearch: string,
  selectedTrainerId: number | string | null = null,
) => {
  const response = await api.get(
    `${
      routes.RECIPES
    }?page=${page}&limit=${pageSize}&search=${debouncedSearch}&sortKey=title&sortDirection=ASC&trainer_id=${
      selectedTrainerId !== null ? Number(selectedTrainerId) : ''
    }`,
  );
  return response.data;
};

export const handleRecipeDelete = async (foodItem: any) => {
  const response = await api.delete(`${routes.RECIPES}/${Number(foodItem)}`);
  return response.data;
};

export const handleRecipeAdding = async ({recipe}: {recipe: Recipe}) => {
  const {
    title,
    image,
    calories_per100g,
    carbs_per100g,
    fat_per100g,
    protein_per100g,
    how_to,
    ingredients,
  } = recipe;
  // if (
  //   title === '' ||
  //   image === '' ||
  //   calories_per100g === null ||
  //   carbs_per100g === null ||
  //   fat_per100g === null ||
  //   protein_per100g === null ||
  //   how_to === '' ||
  //   ingredients === '' ||
  //   trainer_id === '' ||
  //   trainer_id === null
  // ) {
  //   return alert('Please fill all the required fields');
  // }
  const updatedObject = {
    title,
    image,
    calories_per100g: Number(calories_per100g),
    carbs_per100g: Number(carbs_per100g),
    fat_per100g: Number(fat_per100g),
    protein_per100g: Number(protein_per100g),
    how_to,
    ingredients,
  };

  const response = await api.post(`${routes.RECIPES}`, {
    recipes: [{...updatedObject}],
  });
  return response.data;
};

export const handleBulkRecipeAdd = async (recipes: any) => {
  const response = await api.post(`${routes.RECIPES}`, {
    recipes: recipes,
  });
  return response.data;
};

export const handleGetRecipe = async (recipeId: any) => {
  const response = await api.get(`${routes.RECIPES}/${recipeId}`);
  return response.data;
};

export const handleEditRecipe = async ({recipe, recipeId}: any) => {
  const {
    title,
    image,
    calories_per100g,
    carbs_per100g,
    fat_per100g,
    protein_per100g,
    how_to,
    ingredients,
  } = recipe;
  if (
    title === '' ||
    image === '' ||
    calories_per100g === null ||
    carbs_per100g === null ||
    fat_per100g === null ||
    protein_per100g === null ||
    how_to === '' ||
    ingredients === ''
  ) {
    return alert('Please fill all the required fields');
  }
  const updatedObject = {
    title,
    image,
    calories_per100g: Number(calories_per100g),
    carbs_per100g: Number(carbs_per100g),
    fat_per100g: Number(fat_per100g),
    protein_per100g: Number(protein_per100g),
    how_to,
    ingredients,
  };
  const response = await api.put(`${routes.RECIPES}/${recipeId}`, {
    ...updatedObject,
  });
  return response.data;
};
