import React from 'react';
import {useTranslation} from 'react-i18next';
type Props = {
  isActivity?: boolean;
  icon: string;
  title: string;
  score: string | number;
  subtitle: string;
  secondTitle?: string;
  secondScore?: string | number;
  workoutName?: string;
  graph?: React.ReactNode;
};

function ProgressCard({
  isActivity,
  icon,
  title,
  score,
  subtitle,
  secondTitle,
  secondScore,
  workoutName,
  graph,
}: Props) {
  const {t} = useTranslation();
  return (
    <div>
      <div className="flex items-center gap-6">
        <div className="bg-white rounded-full p-4 w-fit">
          <img src={icon} alt={title} />
        </div>
        {isActivity && workoutName && (
          <div>
            <p className="text-sm">{t('lastworkoutname')}</p>
            <p className="font-[600] text-sm">{workoutName}</p>
          </div>
        )}
      </div>
      <div className="flex gap-2">
        <div className="flex-1 flex flex-col">
          <p className="text-[16px] mt-4">{title}</p>
          <p className="text-[20px] font-medium mt-1">
            <span className="text-[28px] font-medium"> {score} </span>{' '}
            {subtitle}
          </p>
        </div>
        {secondScore !== undefined && secondTitle && (
          <div className="flex-1 flex flex-col">
            <p className="text-[16px] mt-4">{secondTitle}</p>
            <p className="text-[28px] font-medium mt-1">{secondScore}</p>
          </div>
        )}
      </div>
      {/* graphs */}
      <div className="flex items-center justify-center">{graph}</div>
    </div>
  );
}

export default ProgressCard;
