import React from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#ffffff',
  border: '1px solid #9C9EB9',
  boxShadow: 24,
  borderRadius: '12px',
  px: 8,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  onClick?: any;
  plans?: any[];
  handlePlanChange: any;
  selectedPlan?: any;
}
const DashboardModal = ({
  isModalOpen,
  setIsModalOpen,
  onClick,
  plans,
  handlePlanChange,
  selectedPlan,
}: Props) => {
  const {t} = useTranslation();
  const handleClose = () => setIsModalOpen(false);
  const handleSubmit = () => {
    onClick();
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        sx={{
          border: 'none',
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description">
        <Box sx={style}>
          <Typography
            id="modal-description"
            sx={{
              fontSize: 20,
              marginBottom: 1,
              fontWeight: 'bold',
              textAlign: 'center',
            }}>
            {t('association')}
          </Typography>
          <Autocomplete
            disablePortal
            size="medium"
            value={selectedPlan ?? null}
            getOptionKey={option => option.id}
            getOptionLabel={option => (option ? `${option.plan_name}` : '')}
            onChange={handlePlanChange}
            options={plans ?? []}
            renderInput={params => (
              <TextField
                {...params}
                label="Plans"
                sx={{
                  width: 360,
                  marginTop: 2,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 4,
              mt: 4,
            }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                borderRadius: '8px',
                border: '1px solid #9C9EB9',
                color: '#000000',
                fontWeight: 500,
                fontSize: 16,
                paddingInline: 5,
                paddingBlock: 1.5,
              }}>
              {t('cancel')}
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                borderRadius: '8px',
                backgroundColor: '#000000',
                color: '#FFFFFF',
                fontWeight: 500,
                fontSize: 16,
                paddingInline: 5,
                paddingBlock: 1.5,
              }}>
              {t('associateBtnTxt')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DashboardModal;
