import React from "react";
import EditClients from "../pages/editClients";
import Clients from "../pages/clients";
import Articles from "../pages/articles";
import Dashboard from "../pages/dashboard";
import EditArticles from "../pages/editArticles";
import NutrisionistPlans from "../pages/nutrisionistPlans";
import EditNutrisionistPlans from "../pages/editNutrisionistPlans";
import Trainers from "../pages/trainers";
import EditTrainer from "../pages/editTrainers";
import EditFood from "../pages/editFoods";
import EditRecipe from "../pages/editRecipe";
import FoodList from "../pages/foodList";
import Recipes from "../pages/recipes";

import { Routes, Route } from "react-router-dom";
type Props = {};

function Index({}: Props) {
  return (
    <div className="h-full overflow-auto">
      <Routes>
        <Route path="/clients" element={<Clients />} />
        <Route path="/addNewClient/:userId?" element={<EditClients />} />
        <Route path="/addNewArticle/:articleId?" element={<EditArticles />} />
        <Route
          path="/nutrition-plans/:planId?"
          element={<EditNutrisionistPlans />}
        />
        <Route path="/nutritionistPlans" element={<NutrisionistPlans />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/trainers" element={<Trainers />} />
        <Route path="/addNewTrainer/:userId?" element={<EditTrainer />} />
        <Route path="/addNewFood/:userId?" element={<EditFood />} />
        <Route path="/addNewRecipe/:userId?" element={<EditRecipe />} />
        <Route path="/foodList" element={<FoodList />} />
        <Route path="/recipes" element={<Recipes />} />
      </Routes>
    </div>
  );
}

export default Index;
