import axios from 'axios';
import {routes} from '../../constants/routes';
import {api} from '../axiosInstance';

export const fetchUsers = async (
  page: number,
  limit: number,
  debouncedSearch: string,
  selectedTrainerId: number | string | null = null,
) => {
  const response = await api.get(
    `${
      routes.USERS
    }?page=${page}&limit=${limit}&sortKey=age&sortDirection=ASC&search=${debouncedSearch}&trainer_id=${
      selectedTrainerId !== null ? Number(selectedTrainerId) : ''
    }`,
  );
  return response.data;
};

export const deleteUser = async (userId: any) => {
  const response = await api.delete(`${routes.USERS}/${Number(userId)}`);
  return response.data;
};

export const handleClientAdd = async ({client, trainer_id}: any) => {
  const {created_by, nutrition_plan, ...filteredClient} = client;
  const response = await api.post('/users', {
    users: [{...filteredClient}],
    trainer_id: trainer_id ? Number(trainer_id) : null,
  });
  return response.data;
};

export const handleBulkClientAdd = async (clients: any) => {
  const response = await api.post('/users', {
    users: clients,
    trainer_id: null,
  });
  return response.data;
};

export const handleClientEdit = async ({userId, client, trainer_id}: any) => {
  const {created_by, nutrition_plan, ...filteredClient} = client;
  const response = await api.put(`/users/${Number(userId)}`, {
    user: {...filteredClient},
    trainer_id: trainer_id ? Number(trainer_id) : null,
  });
  console.log('response', response.data);
  return response.data;
};

export const fetchClient = async (userId: any) => {
  const response = await api.get(`/users/${userId}`);
  return response.data;
};

export const handlePlanAssociation = async ({
  userId,
  selectedPlanId,
}: {
  userId: any;
  selectedPlanId: any;
}) => {
  const response = await api.put(`${routes.USERS}/${userId}`, {
    user: {
      nutrition_plan: selectedPlanId,
    },
    trainer_id: null,
  });
  return response.data;
};

export const fetchUserStats = async (
  userId: number,
  startDate: string,
  endDate: string,
) => {
  const response = await api.get(
    `/stats?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
  );
  return response.data;
};

export const fetchUserStatsAverage = async (trainerId: number | null) => {
  const url = trainerId
    ? `/stats/average?trainerId=${trainerId}`
    : `/stats/average`;
  const response = await api.get(url);
  return response.data;
};

export const fetchUserLocation = async () => {
  const response = await axios.get('https://ipwhois.app/json/');
  return response.data;
};

export const fetchTemperature = async (city: string) => {
  const response = await axios.get(
    `https://wttr.in/${encodeURIComponent(city)}?format=%t`,
  );

  let temperature = response.data.trim();
  // Remove "+" if present at the beginning
  if (temperature.startsWith('+')) {
    temperature = temperature.substring(1);
  }

  return temperature;
};
