import {Sun} from '../../assets';
import {useQuery} from 'react-query';
import {fetchTemperature, fetchUserLocation} from '../../services/clients';
import {CircularProgress} from '@mui/material';
type Props = {};

function Temperature({}: Props) {
  const {data: locationData, isLoading: isLocationLoading} = useQuery({
    queryKey: ['userLocation'],
    queryFn: fetchUserLocation,
    staleTime: 1000 * 60 * 60, // Cache for 1 hour
  });

  // Step 2: Fetch temperature (only if location is available)
  const {data: temperature, isLoading: isTemperatureLoading} = useQuery({
    queryKey: ['temperature', locationData?.city],
    queryFn: () => fetchTemperature(locationData.city),
    enabled: !!locationData?.city, // Only run when city is available
    staleTime: 1000 * 60 * 10, // Cache for 10 minutes
  });

  return (
    <div>
      <div className="px-5 flex items-center justify-between gap-4">
        <img src={Sun} alt="sun" />
        <p style={{direction: 'ltr'}} className="text-4xl font-medium flex">
          {isLocationLoading || isTemperatureLoading ? (
            <CircularProgress size={25} sx={{color: 'black'}} />
          ) : (
            temperature ?? ''
          )}
        </p>
      </div>
    </div>
  );
}

export default Temperature;
