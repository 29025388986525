import {useTranslation} from 'react-i18next';
import {BarChart, Bar, Tooltip, ResponsiveContainer} from 'recharts';

type Props = {workoutEntries: any};

function StackedBarChart({workoutEntries}: Props) {
  const {t} = useTranslation();

  // Check if weight entries are available
  if (workoutEntries.length === 0) {
    return null;
  }

  // Map the weight entries to the format needed by the chart
  const chartData = workoutEntries.map((entry: any) => ({
    activityName: entry.lifestyle_name,
    activityValue: entry.lifestyle_value,
  }));
  return (
    <div className="h-[133px] w-[100%] mt-2">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            bottom: 5,
          }}
          barSize={18}>
          <Tooltip itemStyle={{color: '#85AF3D'}} />
          <Bar
            dataKey={'activityName'}
            name={t('workoutname')}
            stackId="a"
            fill="#fff"
          />
          <Bar
            dataKey={'activityValue'}
            name={t('activity')}
            stackId="a"
            fill="#85AF3D"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default StackedBarChart;
