// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    he: {
      translation: {
        login: "התחבר",
        dashboard: "דשבורד",
        clients: "לקוחות",
        addnewclient: "הוסף לקוח חדש",
        nutritionistplans: "תפריטי תזונה",
        addnewplan: "הוסף תפריט תזונה",
        // articles: 'מאמרים',
        addnewarticle: "הוסף מאמר חדש",
        logout: "התנתק",

        dashboardtitle: `שלום`,
        dashboardsubtitle: `הדשבורד של`,
        statistics: "נתונים סטטיסטיים",
        nutrition: "צריכת מזון",
        calories: "קלוריות",
        protein: "חלבון",
        // fat: 'שומנים',
        Carbs: "פחמימות",

        waterintake: "מים",
        glasses: "כוסות",

        sendapushmessge: "שלח הודעת פוש",
        weightprogress: "התקדמות במשקל",
        progress: "משקל נוכחי",
        targetWeight: "משקל יעד",
        goal: "משקל יעד",
        lastweight: "משקל אחרון",
        kg: "קג",
        caloriesburned: "קלוריות שנשרפו",
        Cal: "קל",
        stepstaken: "צעדים",
        steps: "צעדים",
        workoutname: "שם האימון",
        lastworkoutname: "שם האימון האחרון",
        functionalworkout: "אימון פונקציונאלי",
        activity: "פעילות",
        date: "21 ינואר, 2024",
        adminname: "עידן בכר",
        send: "שלח",
        month: "אוגוסט",

        signup: "הירשם למערכת",
        continue: "הירשם",

        enterverficationcode: "הזן קוד אימות",
        notrecieved: "לא קיבלת?",
        resend: "שולח שוב בעוד",
        privacypolicy: "תקנון | מדיניות פרטיות",

        clientsubheader: "בואו נתחיל לחיות בריא מעכשיו",
        clientheader: "לקוחות",

        averageSteps: "ממוצע הצעדים",
        averageWater: "מספר כוסות מים בממוצע",
        averageWeight: "משקל ממוצע",
        averageNutrition: "ממוצע הקלוריות",
        water: "מים",
        // nutrition: 'תזונה',
        weight: "משקל",
        search: "חיפוש",
        selectcol: "בחר עמודות",

        firstname: "שם פרטי",
        lastname: "שם משפחה",
        phone: "טלפון",
        age: "גיל",
        // currentweight: 'משקל נוכחי',
        planassigned: "תפריט תזונה",
        actions: "פעולות",
        import: "ייבוא",
        export: "ייצוא",

        showing: "מציג 1 עד 8 מתוך 40 פריטים",
        delete: "מחק",
        cancel: "בטל",
        deleteNotification: "האם אתה בטוח שאתה רוצה למחוק את המשתמש הזה?",
        deleteTrainerNotification: "האם אתה בטוח שברצונך למחוק את המאמן הזה?",
        deleteArticleNotification: "האם אתה בטוח שברצונך למחוק את המאמר הזה?",
        deletePlanNotification: "האם אתה בטוח שברצונך למחוק את התוכנית הזו?",
        addeditclient: "הוסף/ערוך לקוח",
        addclientsubheading: "אל תשכח לשמור לאחר העדכון.",
        // personalinfo: 'מידע אישי',
        userphoto: "תמונת פרופיל",
        users: "משתמשים",
        status: "סטטוס",
        phonenumber: "מספר טלפון",
        email: "כתובת מייל",
        birthdate: "תאריך לידה",
        currentweight: "משקל נוכחי",
        height: "גובה",
        preferences: "העדפות ויעדים",
        reminders: "תזכורות",
        food: "מזון",

        dailygoalnutrition: "צריכת קלוריות יומית",
        dailygoalwater: "צריכת מים יומית",
        dailygoalactivity: "זמן הליכה (בדקות)",
        dailygoalweight: "משקל התחלתי",
        dailygoalsteps: "כמות צעדים יומית מומלצת",
        fitnesslevel: " רמת כושר",
        good: "מתאמן קבוע",
        average: "מתאמן לפעמים",
        bad: "לא מתאמן",
        targetweight: "משקל מטרה",
        timetosleep: "זמן לישון",

        health: "מידע בריאותי",
        healthneed: "צרכים בריאותיים",
        healthissues: "בעיות בריאות",
        healthstatus: "מצב בריאותי",
        bloodpressure: "לחץ דם",
        bloodsugar: "סוכר בדם",
        medicalrecommedations: "המלצות רפואיות",
        pregnant: "בהריון",
        breastfeeding: "מניקה",
        excerciselimitation: "הגבלת פעילות גופנית",
        foodpreferences: "העדפות תזונה",
        foodsensetivity: "רגישות לאוכל",
        foodmethod: "סגנון ארוחה",
        specialpreference: "העדפת תזונה",
        vegetarian: "צמחוני",
        vegan: "טבעוני",
        fruity: "פירותי",

        // nutritionistplans: 'תפריטי תזונה',
        nutritionistsubheadings: "בואו נתחיל לחיות בריא מעכשיו",
        planname: "שם התוכנית",
        plantags: "תגיות",
        plangender: "מין",
        planages: "גילאים",
        planType: "סוג תוכנית",
        addnutrisionistplan: "הוסף/ערוך תפריט תזונה",
        addnutrisionistplanssubheadings: "אל תשכח לשמור לאחר העדכון.",
        personalinfo: "מידע אישי",

        female: "נקבה",
        male: "זכר",
        other: "אחר",
        otherGender: "אחר",
        from: "מ",
        to: "עד",
        plannotes: "הערות",
        plancreation: "תאריך יצירת התוכנית",
        menu: "תפריט",
        breakfast: "א. בוקר",
        nameoffood: "שם המזון",
        measurementtype: "מידה",
        gram: "גרם",
        spoon: "כף",
        glass: "כוס",
        milliliter: "מיליליטר",
        mealnotes: "הערות/הגבלות לארוחה",
        afterbrekfast: "אחרי א. בוקר",
        lunch: "צהריים",
        afterlunch: "אחרי צהריים",
        evening: "ערב",
        save: "שמירה",
        total: "סה״כ",
        carbs: "פחמימה",
        fat: "שומן",
        quantity: "כמות",

        articles: "מאמרים",
        articlessubheading: "",
        article1: "הבנת כולסטרול: הטוב, הרע והמכוער",
        article2: "מה אם תוכנית התזונה שלי אינה מכוסה בביטוח?",
        article3: "הבנת כולסטרול: הטוב, הרע והמכוער",
        article4: "מה אם תוכנית התזונה שלי אינה מכוסה בביטוח?",

        articleedit: "עריכת מאמר",
        articlesubheading: " בואו נתחיל לחיות בריא מעכשיו ",
        articletitle: "כותרת המאמר",
        category: "קטגוריה",
        readingtime: "זמן קריאה(בדקות)",
        articleImage: "תמונת המאמר",
        content: "תוכן",
        articleimage: "תמונת מאמר",

        signupscaleapp: "הירשם למערכת",

        weightLoss: "הרזיה",
        muscleGain: "עלייה בשרירים",
        toning: "חיטוב",
        bodyRecomposition: "העלאה במסה והורדת אחוזי שומן",
        obesity: "השמנה",
        hormonalBalance: "איזון הורמונלי",
        pregnancy: "הריון",
        minute: "דקה",
        sendOtp: "שלח otp",
        verifyOtp: "לאמת otp",
        picture: "תְמוּנָה",
        association: "תוכנית תזונה",
        associateBtnTxt: "בחר",
        planAssigned: "תוכנית מוקצה",
        admins: "מנהלי מערכת",
        trainers: "מאמנים",
        trainer: "מאמן",
        nutritionist: "תזונאי",
        addNewTrainer: "הוסף מאמן חדש",
        addEditTrainer: "הוסף/ערוך מאמן",

        partnerType: "סוג פרטנר",
        partnerFullName: "שם מלא של הפרטנר",

        addNewFood: "הוסף ערכי אוכל",
        addEditFood: "הוסף/ערוך מזון",
        foodDetails: "פרטי מזון",

        foodName: "שם אוכל",
        carbsPer: "פחמימות (ל-100 גרם)",
        fatPer: "שומן (ל-100 גרם)",
        proteinPer: "חלבון (ל-100 גרם)",
        caloriesPer: "קלוריות (ל-100 גרם)",

        addNewRecipe: "הוסף מתכון חדש",
        addEditRecipe: "הוסף/ערוך מתכון",
        recipeDetails: "פרטי מתכון",
        recipePhoto: "תמונה של מתכון",
        photo: "תמונה",

        howtomake: "איך להכין",
        ingredients: "מרכיבים",

        foodList: "רשימת מזון",
        foodValues: "ערכי אוכל",
        deleteFoodItem: "האם אתה בטוח שברצונך למחוק פריט מזון זה?",
        recepieTitle: "כותרת המתכון",
        recipes: "מתכונים",
        deleteRecipeItem: "האם אתה בטוח שברצונך למחוק את המתכון הזה?",

        grams: "גרם",

        image: "תמונה",

        meal: "ארוחה",

        // Api Errors
        numberExits: "הלקוח כבר קיים במערכת",
        numberExitsBulk: "לקוח אחד או יותר כבר קיים במערכת",
        noRecords: "לא נמצאו רשומות לייצא",
      },
    },
    en: {
      translation: {
        login: "Login",
        dashboard: "Dashboard",
        clients: "Clients",
        addnewclient: "Add New Client",
        nutritionistplans: "Nutritionist Plans",
        addnewplan: "Add New Plan",
        // articles: 'Articles',
        addnewarticle: "Add New Article",
        logout: "Log Out",

        dashboardtitle: `Hello`,
        dashboardsubtitle: `Dashboard`,
        statistics: "Statistics",
        nutrition: "Nutrition",
        calories: "Calories",
        protein: "Protein",
        // fat: 'Fat',
        Carbs: "Carbs",
        quantity: "Quantity",

        waterintake: "Water Intake",
        glasses: "Glasses",
        steps: "Steps",
        sendapushmessge: "Send a push message",
        weightprogress: "Weight Progress",
        progress: "Progress",
        targetWeight: "Target Weight",
        goal: "Goal",
        lastweight: "Last Weight",
        caloriesburned: "Calories Burned",
        Cal: "cal",
        kg: "kg",
        stepstaken: "Step Taken",
        workoutname: "Workout Name",
        lastworkoutname: "Last Workout Name",
        functionalworkout: "Functional Workout",
        activity: "Activity",

        adminname: "Idan Bachar",
        date: "January 21, 2024",

        send: "Send",
        month: "August",
        signupscaleapp: "Signup",
        continue: "Continue",
        enterverficationcode: "Enter a Verification Code",
        notrecieved: "Didn’t receive? ",
        resend: "Resend in ",
        privacypolicy: "Regualtions | Privacy Policy",

        clientheader: "Clients",
        clientsubheader: "Lets start living healthy from now on",

        averageSteps: "Average Steps",
        averageWater: "Average Number of Glasses of Water",
        averageWeight: "Average Weight",
        averageNutrition: "Average Calories",
        water: "Water",
        // nutrition: 'Nutrition',
        weight: "Weight",
        search: "Search",
        selectcol: "Select Col",

        firstname: "First Name",
        lastname: "Last name",
        phone: "Phone",
        age: "Age",
        // currentweight: 'Current Weight',
        planassigned: "Plan Assigned",
        actions: "Actions",
        import: "Import",
        export: "Export",
        showing: "Showing 1 to 8 of 40 items",

        delete: "Delete",
        cancel: "Cancel",
        deleteNotification: "Are you sure you want to delete this client?",
        deleteTrainerNotification:
          "Are you sure you want to delete this trainer?",
        deleteArticleNotification:
          "Are you sure you want to delete this Article?",
        deletePlanNotification: "Are you sure you want to delete this plan?",

        addeditclient: "Add/Edit Client",
        addclientsubheading: "Dont forget to save after update.",
        // personalinfo: 'Personal information',
        userphoto: "User Photo",
        users: "Users",
        status: "Status",
        phonenumber: "Phone Number",
        email: "Email",
        birthdate: "Birthdate",
        currentweight: "Current Weight",
        height: "Height",
        preferences: "Preferences & Goals",
        reminders: "Reminders",
        food: "Food",
        dailygoalnutrition: " User daily goal nutrition",
        dailygoalwater: " User daily goal water",
        dailygoalactivity: " User daily goal activity",
        dailygoalweight: " User daily goal weight",
        dailygoalsteps: " User daily goal steps",
        fitnesslevel: " Fitness level",
        good: "Training regularly",
        average: "Training sometimes",
        bad: "Not training",
        targetweight: "Target weight",
        timetosleep: "Time to sleep",

        health: "Health Information",
        healthneed: "Health need",
        healthissues: "Health issues",
        healthstatus: "Health status",
        bloodpressure: "Blood Pressure",
        bloodsugar: "Blood sugar",
        medicalrecommedations: "Medication recommendations",
        pregnant: "Pregnant",
        breastfeeding: "Breast feeding",
        excerciselimitation: "Exercise limitation",
        foodpreferences: "Food preferences",
        foodsensetivity: "Food Sensetivity",
        foodmethod: "Food method",
        specialpreference: "Special Preferences",
        vegetarian: "Vegetarian",
        vegan: "Vegan",
        fruity: "Fruity",

        // nutritionistplans: 'Nutritionist Plans',
        nutritionistsubheadings: "Lets start living healthy from now on",
        planname: "Plan name",
        plantags: "Plan Tags",
        plangender: "Gender",
        planages: "Plan Ages",
        planType: "Plan Type",
        addnutrisionistplan: "Add/Edit Nutritionist Plan",
        addnutrisionistplanssubheadings: "Dont forget to save after update.",
        personalinfo: "Personal information",
        female: "Female",
        male: "Male",
        other: "Other",
        otherGender: "Other",
        from: "From",
        to: "To",
        plannotes: "Plan Notes",
        plancreation: "Plan creation date",
        menu: "Menu",
        breakfast: "Breakfast",
        nameoffood: "Name of food",
        measurementtype: "Measurement type",
        gram: "Gram",
        spoon: "Spoon",
        glass: "Glass",
        milliliter: "Milliliter",
        mealnotes: "Meal Notes/Restrictions",
        afterbrekfast: "After breakfast",
        lunch: "Lunch",
        afterlunch: "After lunch",
        evening: "Dinner",
        save: "Save",
        total: "Total",

        carbs: "Carbs",
        fat: "Fat",

        articles: "Articles",
        articlessubheading: "Lets start living healthy from now on",
        article1: "Understanding Cholesterol: The Good, The Bad, and The Ugly",
        article2: "What If My Nutrition Program is Not Covered By Insurance?",
        article3: "Understanding Cholesterol: The Good, The Bad, and The Ugly",
        article4: "What If My Nutrition Program is Not Covered By Insurance?",

        articleedit: "Article Edit",
        articlesubheading: "Lets start living healthy from now on",
        articletitle: "Article Title",
        category: "Category",
        readingtime: "Reading Time(Min)",
        articleImage: "Article Image",
        content: "Content",
        articleimage: "Article Image",

        weightLoss: "Weight Loss",
        muscleGain: "Muscle Gain",
        obesity: "Obesity",
        toning: "Toning",
        bodyRecomposition: "Increase in mass and decrease in fat percentage",
        hormonalBalance: "Hormonal Balance",
        pregnancy: "Pregnancy",
        minute: "Min",
        sendOtp: "Send Otp",
        verifyOtp: "Verify Otp",
        picture: "Picture",
        association: "Nutrition Plan",
        associateBtnTxt: "Confirm",
        planAssigned: "Plan Assigned",
        admins: "Admins",
        trainers: "Trainers",
        trainer: "Trainer",
        nutritionist: "Nutritionist",
        addNewTrainer: "Add New Trainer",
        addEditTrainer: "Add/Edit Trainer",

        partnerType: "Partner Type",
        partnerFullName: "Partner Full Name",

        addNewFood: "Add Food Values",
        addEditFood: "Add/Edit Food",
        foodDetails: "Food Details",

        foodName: "Food Name",

        carbsPer: "Carbs (per 100 grams)",
        fatPer: "Fat (per 100 grams)",
        proteinPer: "Protein (per 100 grams)",
        caloriesPer: "Calories (per 100 grams)",

        addNewRecipe: "Add New Recipe",
        addEditRecipe: "Add/Edit Recipe",
        recipeDetails: "Recipe Details",
        recipePhoto: "Recipe Photo",
        photo: "Photo",

        howtomake: "How to make",
        ingredients: "Ingredients",
        foodList: "Food List",
        foodValues: "Food Values",

        deleteFoodItem: "Are you sure you want to delete this food item?",
        recepieTitle: "Recipe Title",
        recipes: "Recipes",
        deleteRecipeItem: "Are you sure you want to delete this recipe?",

        grams: "g",

        image: "Image",

        meal: "Meal",

        // Api Errors
        numberExits: "The client already exists in the system",
        numberExitsBulk: "One or more clients already exists in the system",
        noRecords: "No records found",
      },
    },
  },
  lng: "he",
  fallbackLng: "he",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
