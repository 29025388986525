import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

type Props = {caloriesEntries: any};

function CustomBarChart({caloriesEntries}: Props) {
  const {t} = useTranslation();

  // Check if weight entries are available
  if (caloriesEntries.length === 0) {
    return null;
  }

  // Map the weight entries to the format needed by the chart
  const chartData = caloriesEntries.map((entry: any) => ({
    name: moment(entry.datetime).format('DD/MM/YY'), // Format datetime as DD/MM/YY
    caloriesBurned: entry.calories_burned, // lifestyle_value to be shown on Y axis
  }));

  return (
    <div className="h-[123px] w-[100%] mt-2">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            bottom: 5,
          }}
          barSize={18}>
          <Tooltip />
          <Bar
            dataKey="caloriesBurned"
            name={t('calories')}
            fill="#5046C0"
            background={{fill: 'white'}}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CustomBarChart;
