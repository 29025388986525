import React, {useEffect, useState} from 'react';
import Header from '../../components/Header';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {Checkbox, ListItemText, Menu, MenuItem} from '@mui/material';
import * as XLSX from 'xlsx';
import FoodIngredientsTable from './foodTable';
import {useTranslation} from 'react-i18next';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import Loader from '../../components/Loader';
import {
  ImportSvg,
  ExportSvg,
  ColSelectSvg,
  EditSvg,
  BasketSvg,
} from '../../assets';
import {handleExport, downloadBlob} from '../../services/importAndExport';
import {routes} from '../../constants/routes';
import {useDebounce} from '../../services/debounce';
import IconInput from '../../components/IconInput';
import TrainerSelector from '../../components/TrainerSelector';
import {
  handleBulkFoodIngredientAdd,
  handleFetchFoodIngredients,
  handleFoodIngredientDelete,
} from '../../services/foodIngredients';
import {AxiosError} from 'axios';

type Props = {};

const isValidData = (data: any) => {
  const keys = Object.keys(data[0]);
  if (
    keys.length > 0 &&
    keys.includes('item_name') &&
    keys.includes('item_calories_per100g') &&
    keys.includes('item_carbs_per100g') &&
    keys.includes('item_fat_per100g') &&
    keys.includes('item_protein_per100g')
  ) {
    return true;
  } else {
    return false;
  }
};

function Index({}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: 'item_name',
      headerName: t('foodName'),
      width: 150,
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'item_calories_per100g',
      headerName: t('calories'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'item_carbs_per100g',
      headerName: t('carbs'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'item_fat_per100g',
      headerName: t('fat'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'item_protein_per100g',
      headerName: t('protein'),
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 150,
      minWidth: 150,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="flex justify-center items-center gap-4">
          <img
            src={EditSvg}
            alt="Edit"
            onClick={() => handleEdit(params.row)}
            className="cursor-pointer"
          />
          <img
            src={BasketSvg}
            alt="Delete"
            onClick={() => handleDelete(params.row.id)}
            className="cursor-pointer"
          />
        </div>
      ),
    },
  ];
  const [tableColumns, setTableColumns] = useState(
    columns.map(col => col.field),
  );
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [delFoodId, setDelFoodId] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const debouncedSearch = useDebounce(searchQuery, 300);
  // const [selectedTrainerId, setSelectedTrainerId] = useState<
  //   number | string | null
  // >(null);
  const {
    data: foodItemsData,
    error: foodItemsError,
    isLoading: foodItemsLoading,
  } = useQuery(
    ['foodItems', page, pageSize, debouncedSearch],
    () => handleFetchFoodIngredients(page, pageSize, debouncedSearch),
    {
      keepPreviousData: true,
    },
  );

  const {
    mutate: deleteWithQuery,
    isLoading: isDeleting,
    isError,
  } = useMutation(handleFoodIngredientDelete, {
    onSuccess: data => {
      queryClient.invalidateQueries(['foodItems']);
      setIsModalOpen(false);
      if (data) {
        alert('foodItems deleted successfully');
      }
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  const {
    mutate: exportData,
    isLoading: isExporting,
    isError: isExportError,
  } = useMutation(handleExport, {
    onSuccess: data => {
      downloadBlob(data, 'foodIngredients_exported_file.csv');
    },
    onError: (error: AxiosError<{message: string}>) => {
      let errorMessage = '';
      if (error?.response?.status == 404) {
        errorMessage = t('noRecords');
      } else {
        errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'An unexpected error occurred.';
      }
      alert(errorMessage);
    },
  });

  useEffect(() => {
    let prevSelectedColumns = localStorage.getItem('foodsSelectedColumns');
    if (prevSelectedColumns) {
      setTableColumns(JSON.parse(prevSelectedColumns));
    }
  }, []);

  const handleEdit = (user: any) => {
    navigate(`/addNewFood/${user.id}`);
  };
  const handleDelete = (id: number) => {
    setDelFoodId(id);
    setIsModalOpen(true);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (field: string) => {
    const updatedColumns = tableColumns.includes(field)
      ? tableColumns.filter(item => item !== field)
      : [...tableColumns, field];

    setTableColumns(updatedColumns);
    localStorage.setItem(
      'foodsSelectedColumns',
      JSON.stringify(updatedColumns),
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async e => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, {type: 'array'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        console.log('Imported food items are ==>:', jsonData);

        if (isValidData(jsonData)) {
          // Validate each user before submitting
          const validFoodItems = jsonData.filter((food: any) => {
            if (
              food?.item_name &&
              food?.item_calories_per100g &&
              food?.item_fat_per100g &&
              food?.item_protein_per100g
            ) {
              return true;
            } else {
              return false;
            }
          });
          if (validFoodItems.length === 0) {
            return alert('No valid food items to submit.');
          }

          await bulkFoodItemAdd(validFoodItems);
        } else {
          alert('Invalid file format');
          return;
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const {
    mutate: bulkFoodItemAdd,
    isLoading: isBulkAdding,
    isError: isBulkError,
  } = useMutation(handleBulkFoodIngredientAdd, {
    onSuccess: data => {
      queryClient.invalidateQueries(['foodItems']);
      if (data) {
        alert('Food Items added successfully');
      }
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  if (isDeleting || foodItemsLoading || isBulkAdding) {
    return <Loader />;
  }
  if (foodItemsError) {
    return <div>Error while fetching food item data</div>;
  }
  return (
    <>
      <div className="bg-light-green h-full p-8">
        <Header
          title={t('foodValues')}
          subtitle={t('clientsubheader')}
          firstBtnTxt={t('import')}
          firstBtnIcon={ImportSvg}
          secondBtnTxt={t('export')}
          secondBtnIcon={ExportSvg}
          handleSubmitSecond={() =>
            exportData({route: routes.EXPORT_FOOD_INGREDIENTS})
          }
          handleSubmit={() =>
            document.getElementById('xlsxUploadInput')?.click()
          }
          secondBtnLoading={isExporting}
        />
        <input
          id="xlsxUploadInput"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{display: 'none'}}
        />
        {/* subheader */}
        <div className="flex items-center justify-end mt-8">
          <div className="flex gap-4">
            {/* <TrainerSelector
              selectedTrainerId={selectedTrainerId}
              setSelectedTrainerId={setSelectedTrainerId}
            /> */}
            <IconInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />

            <div
              onClick={handleClick}
              className="flex items-center gap-2 hover:cursor-pointer">
              <img src={ColSelectSvg} alt="sort" className="size-4" />
              <p className="text-dark-text text-[16px]">{t('selectcol')}</p>
            </div>
            <div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {columns.map((option: any) => (
                  <MenuItem key={option.field}>
                    <Checkbox
                      checked={tableColumns.includes(option.field)}
                      onChange={() => handleToggle(option.field)}
                    />
                    <ListItemText primary={option.headerName} />
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        {/* clients table */}
        <div className="mt-4 h-[calc(100%-160px)]">
          <FoodIngredientsTable
            searchQuery={searchQuery}
            columns={columns.filter(col => tableColumns.includes(col.field))}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            deleteWithQuery={deleteWithQuery}
            delFoodId={delFoodId}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            foodItemsData={foodItemsData}
          />
        </div>
      </div>
    </>
  );
}

export default Index;
