import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

type Props = {selectedUser: any; weightEntries: any};

function CustomLineChart({selectedUser, weightEntries}: Props) {
  const {t} = useTranslation();

  // Check if weight entries are available
  if (weightEntries.length === 0 || !selectedUser) {
    return null;
  }

  // Map the weight entries to the format needed by the chart
  const chartData = weightEntries.map((entry: any) => ({
    name: moment(entry.datetime).format('DD/MM/YY'), // Format datetime as DD/MM/YY
    [t('weight')]: entry.lifestyle_value, // lifestyle_value to be shown on Y axis
    [t('targetWeight')]: selectedUser?.target_weight,
  }));

  return (
    <div className="h-[250px] w-full mt-2">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{fontSize: '14px', fontWeight: '400'}}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickMargin={30}
            tickCount={4}
            tick={{fill: '#9C9EB9', fontSize: '14px', fontWeight: '600'}}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey={t('weight')}
            stroke="#000"
            activeDot={{r: 6}}
            strokeWidth={1.5}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey={t('targetWeight')}
            stroke="#7ECD93"
            activeDot={{r: 6}}
            strokeWidth={1.5}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CustomLineChart;
