import React from "react";
import { useTranslation } from "react-i18next";
import TrainerSelector from "../../components/TrainerSelector";

type Props = {
  foodIngredient: any;
  setFoodIngredient: (client: any) => void;
  // selectedTrainerId: any;
  // setSelectedTrainerId: (val: any) => void;
};

function AddFoods({
  foodIngredient,
  setFoodIngredient,
}: // selectedTrainerId,
// setSelectedTrainerId,
Props) {
  const { t } = useTranslation();

  const column1 = [
    {
      id: 2,
      key: "item_name",
      label: t("foodName"),
      type: "text",
    },
    {
      id: 3,
      key: "item_calories_per100g",
      label: t("caloriesPer"),
      type: "number",
    },
    {
      id: 4,
      key: "item_carbs_per100g",
      label: t("carbsPer"),
      type: "number",
    },
    {
      id: 5,
      key: "item_fat_per100g",
      label: t("fatPer"),
      type: "number",
    },
    {
      id: 6,
      key: "item_protein_per100g",
      label: t("proteinPer"),
      type: "number",
    },
  ];

  const handleChange = (key: string, value: any) => {
    setFoodIngredient((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="border border-border-color rounded-[15px] h-[calc(100%-90px)] overflow-auto grid grid-cols-1 mt-4 p-6 gap-24">
      <div className="flex w-full">
        <div className="w-full">
          <p className="text-[20px] font-medium mb-2">{t("foodDetails")}</p>

          {column1.map((item) => (
            <div className="mt-6" key={item.id}>
              <div className="mt-4 w-[25%]">
                <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                  {item.label}
                </p>
                <input
                  type={item.type}
                  name={item.key}
                  value={foodIngredient[item.key]}
                  onChange={(e) => handleChange(item.key, e.target.value)}
                  className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                />
              </div>
            </div>
          ))}
          {/* <div className="mt-4">
            <p className="text-xs font-[400] text-dark-text mb-1">
              {t("trainers")}
            </p>
            <TrainerSelector
              selectedTrainerId={selectedTrainerId}
              setSelectedTrainerId={setSelectedTrainerId}
              width={"25%"}
              isShowLabel={false}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AddFoods;
